import React from "react";
import { Empty, Button } from "antd";
import "./index.less";

export default (props) => {
  const { history } = props;
  const goBack = () => {
    history.push("/");
  };
  return (
    <div style={{ marginTop: 40 }}>
      <Empty
        image={require("@/assets/images/404.png")}
        imageStyle={{ height: 250 }}
        description={
          <>
            <span className="header">Страница не найдена</span>
            <br />
            <Button type="link" onClick={goBack}>
              Вернуться на главную
            </Button>
          </>
        }
      />
    </div>
  );
};
