import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, PageHeader, Tag, Divider } from "antd";
import { Button, Modal, DatePicker, notification } from "antd";
import { Empty, Select } from "antd";
import moment from "moment";
import ScenarioPreview from "@/screens/Scenarios/Preview";
import { ClockCircleOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { Loading } from "@/ui";
import Socket from "@/ui/Socket";
import { call } from "@/actions/axios";
import { SET_APP } from "@/actions/app";
import defaults from "@/defaults";
import "./index.less";

const { Option } = Select;
const Context = React.createContext();

const Screen = (props) => {
  const { history, match } = props;

  const [scenario, setScenario] = React.useState(null);
  const [abonent, setAbonent] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [ready, setReady] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [recalling, setRecalling] = React.useState(false);
  const [closable, setClosable] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [callState, setCallState] = React.useState("Идет разговор");

  const user = useSelector((state) => state.auth.user);
  const cust_id = useSelector((state) => state.auth.cust_id);
  const request = useSelector((state) => state.app.request);
  const campaign = useSelector((state) => state.app.campaign);

  const dispatch = useDispatch();
  const socket = React.useRef();

  const onCompleteCall = async () => {
    const { id } = campaign;
    const { external_id, operator_msisdn } = abonent;
    await dispatch(
      call({
        url: `/lists/${cust_id}/endcall`,
        method: "POST",
        data: {
          id_in_list: external_id,
          request_id: request.id,
          campaign_id: id,
          msisdn: operator_msisdn,
        },
      })
    );
  }

  const onRecall = async () => {
    const { id } = campaign;
    const { external_id, operator_msisdn } = abonent;
    await dispatch(
      call({
        url: `/lists/${cust_id}/delay`,
        method: "POST",
        data: {
          id_in_list: external_id,
          delay_to: date,
          request_id: request.id,
          campaign_id: id,
          msisdn: operator_msisdn,
        },
      })
    );
    setRecalling(false);
  };

  const resetCall = async () => {
    const id_in_list = match.params.id;
    const { msisdn } = user;
    await dispatch(
      call({
        url: `/lists/${cust_id}/reset`,
        method: "POST",
        data: { msisdn, id_in_list },
      })
    );
    history.push("/arm");
    notification.error({
      message: "Ошибка",
      description: "Неизвестная ошибка!",
    });
  };

  React.useEffect(() => {
    if (!match.params.id) history.push("/arm");
    if (!campaign || !request) return resetCall();

    const getAbonent = async () => {
      const { data } = await dispatch(
        call({
          url: `/lists/${match.params.id}/get`,
          method: "GET",
        })
      );
      setAbonent(data);
      setReady(true);
    };
    getAbonent();
  }, [match, campaign, request]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    socket.current = Socket("arm");
    socket.current.on("update-abonent", async (abonent) => {
      // console.log(abonent);
      const { id, dialing_status } = abonent;
      if (match.params.id.toString() === id.toString()) {
        setClosable(true);
        setFinished(true);
      }
      switch (dialing_status) {
        case "DIALING": setCallState("Идет набор номера"); break;
        case "ANSWERED": setCallState("Звонок завершен"); break;
        case "NO_ANSWER": setCallState("Абонент не отвечает"); break;
        case "BUSY": setCallState("Абонент занят"); break;
        case "CONVERSATION": setCallState("Идет разговор"); break;
      }
    });
    return () => {
      if (socket.current) socket.current.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const getScenario = async () => {
      const { data } = await dispatch(
        call({
          url: `/scenarios/${cust_id}/${campaign.id}`,
          method: "GET",
        })
      );

      if (data) setScenario(data);
      else setScenario(-1);
    };
    const getStatus = async () => {
      const { data } = await dispatch(
        call({
          url: `/statuses/${cust_id}/${campaign.id}`,
          method: "GET",
        })
      );

      if (data) setStatus(data);
      else setStatus(-1);

      if(data && data.data){
        const s = _.find(data.data, { value: abonent.status });
        if (s) setSelectedStatus(s.id);
      }
    };
    if (ready) {
      getScenario();
      getStatus();
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => {
    dispatch(SET_APP(["campaign"], null));
    dispatch(SET_APP(["request"], null));
    dispatch(SET_APP(["item"], null));
    history.push("/arm");
  };

  const values = { abonent, campaign, request };

  const handleChange = async (value) => {
    setSelectedStatus(value);
    const s = _.find(status.data, { id: value });
    await dispatch(
      call({
        url: `/lists/${match.params.id}/status`,
        method: "PATCH",
        data: {
          status: s.value,
        },
      })
    );
  };

  if (!ready) return <Loading />;

  return (
    <Context.Provider value={values}>
      <PageHeader
        title={<Title finished={finished}/>}
        extra={[
          <div className="call-state-string" key="call-state">{callState}</div>,
          <Button onClick={() => setRecalling(true)} key="delay">
            <FieldTimeOutlined /> Отложить звонок
          </Button>,
          <Button onClick={() => onCompleteCall()} key="success">
            Завершить звонок
          </Button>,
          <Button
            key="close"
            type="primary"
            disabled={!closable}
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
      <Row className="main__row">
        <Col className="col__padding" span={6}>
          <div className="client__card">Статус по абоненту</div>
          {status && status !== -1 && (
            <Select
              value={selectedStatus}
              style={{ width: "100%", marginTop: 12 }}
              onChange={handleChange}
            >
              {status.data.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              ))}
            </Select>
          )}
          {!status && <Loading />}
          {status === -1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Col>
        <Col className="col__padding" span={10}>
          <div className="client__card">Сценарий кампании</div>
          {scenario && scenario !== -1 && (
            <ScenarioPreview scenario={scenario} abonent={abonent} />
          )}
          {!scenario && <Loading />}
          {scenario === -1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Col>
        <Col className="col__padding" span={7}>
          {request.is_target && (
            <>
              <div className="client__card">Карточка клиента</div>
              <div className="client__card__content">
                {request.headers.map((h, i) => {
                  const v = abonent.data[i];
                  return (
                    <React.Fragment key={i}>
                      <div className="client__card__title">{h}</div>
                      <div className="client__card__description">{v}</div>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
        </Col>
      </Row>
      <Modal
        title={null}
        closable={false}
        visible={recalling}
        onOk={onRecall}
        onCancel={() => setRecalling(false)}
      >
        <DatePicker
          showNow={false}
          showTime={{ format: "HH:mm", minuteStep: 5 }}
          placeholder="Выберите дату и время"
          format="DD.MM.YYYY HH:mm"
          style={{ width: "100%" }}
          onChange={(d) => {
            setDate(d.utc().format("YYYY-MM-DD[T]HH:mm[Z]"));
          }}
        />
      </Modal>
    </Context.Provider>
  );
};

const Title = ({finished}) => {
  const context = React.useContext(Context);
  const { campaign, abonent } = context;

  const [timer, setTimer] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const interval = React.useRef();

  React.useEffect(() => {
    const getTimer = async () => {
      const t = moment.utc().diff(moment.utc(abonent.startedAt), "seconds");
      setReady(true);
      setTimer(t);
      interval.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    };
    getTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(()=>{
    if(finished){
      // console.log('timer off')
      clearInterval(interval.current);
    }
  },[finished])
  let title = "ID абонента: " + abonent.external_id;
  if (campaign.scenario_id.toString() === defaults.types.OPERATORS)
    title = abonent.msisdn;

  return (
    <>
      {title}
      {ready && (
        <Tag
          style={{ marginLeft: 16 }}
          icon={<ClockCircleOutlined />}
          color="default"
        >
          {moment().startOf("day").seconds(timer).format("HH:mm:ss")}
        </Tag>
      )}
    </>
  );
};

export default Screen;
