import React from "react";
import Message from "./Message";

const Footer = ({ lang = "ru" }) => (
  <footer className="main-footer text-white d-flex justify-content-between">
    <ul className="d-lg-flex justify-content-lg-between">
      <li className="list-unstyled pr-lg-5 pr-lg-4 pr-3">
        <a href={`https://www.kcell.kz/${lang}/article/217/528`}>
          {Message.paymentTerms[lang]}
        </a>
      </li>
      <li className="list-unstyled  pr-lg-5 pr-lg-4 pr-3">
        <a href={`https://www.kcell.kz/${lang}/b2b.account/eregistry/2369`}>
          {Message.eRegister[lang]}
        </a>
      </li>
      <li className="list-unstyled pr-lg-5 pr-lg-4 pr-3">
        <a href={`https://www.kcell.kz/${lang}/article/2585/527`}>
          {Message.applicationSamples[lang]}
        </a>
      </li>
      <li className="list-unstyled pr-lg-5 pr-lg-4 pr-3">
        <a href={`https://www.kcell.kz/${lang}/service/216/532`}>
          {Message.automaticBalanceNotification[lang]}
        </a>
      </li>
      <li className="list-unstyled pr-3">
        <a href={`https://www.kcell.kz/${lang}/office-contacts/contacts`}>
          {Message.contacts[lang]}
        </a>
      </li>
    </ul>
    <span className="divider d-none d-lg-flex" />
    <p className="copyright t-regular">{Message.footerText[lang]}</p>
  </footer>
);

export default Footer;
