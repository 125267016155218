import React from "react";
import { connect } from "react-redux";
import { PageHeader, Col, Card } from "antd";
import { Step1 } from "./Steps";
import "./index.less";

const Screen = () => {
  return (
    <Col span={16}>
      <PageHeader title="Добавить оператора" />
      <Card bordered={false} className="create-container">
        <Step1 />
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  operator: state.app.operator,
});

export default connect(mapStateToProps)(Screen);
