import ExcelExport from "export-xlsx";

const getSettings = (fileName, sheetName, title) => ({
  // Table settings
  fileName: fileName,
  workSheets: [
    {
      sheetName: sheetName,
      startingRowNumber: 1,
      gapBetweenTwoTables: 2,
      tableSettings: {
        table1: {
          tableTitle: title,
          headerDefinition: [],
        }
      }
    },
  ],
});

export const exportToExcel = (rows, {columns, fileName, sheetName, title}) => {
  const settings = getSettings(fileName, sheetName, title);
  const headers = [];
  for (const column of columns){
    headers.push({
      key: column.dataIndex,
      name: column.title
    })
  }
  settings.workSheets[0].tableSettings.table1.headerDefinition = headers;
  // console.log(settings);
  const data = [
    {
      table1: rows
    }
  ]
  const ee = new ExcelExport();
  ee.downloadExcel(settings, data).then(()=>console.log('DONE'))
}
