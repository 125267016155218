import React from "react";
import { useDispatch } from "react-redux";
import { call } from "@/actions/axios";

export default function useFetchingStatus(cust_id, msisdn) {
  const [status, setStatus] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      if (fetching) return;

      const { data } = await dispatch(
        call({
          url: `/api/customer/operators/msisdns/assigned/${cust_id}/${msisdn}/presence`,
        })
      );
      if (data && data.operator_presence_status) {
        setStatus(data.operator_presence_status);
      }
      setFetching(false);
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [status, setStatus];
}
