import React from "react";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { PageHeader, Input, Dropdown, Card, Menu, Radio } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SET_APP, GET_CAMPAIGNS, GET_DELETED_CAMPAIGNS } from "@/actions/app";
import { statuses } from "./Table/data";
import Table from "./Table";
import "./index.less";

const Screen = (props) => {
  const [search, setSearch] = React.useState();
  const [selected, setSelected] = React.useState("active");
  const { data, loading } = props;

  const onCreate = () => {
    props.history.push("/campaigns/new");
  };

  const onMenu = (e) => {
    localStorage.setItem("backUrl", "/campaigns");
    props.history.push(`/${e.key}`);
  };

  const menu = (
    <Menu onClick={onMenu}>
      <Menu.Item key="statuses">Статусы обзвона</Menu.Item>
      <Menu.Item key="calendars">Календарные интервалы</Menu.Item>
      <Menu.Item key="scenarios">Сценарии</Menu.Item>
    </Menu>
  );

  const dispatch = useDispatch();
  const types = useSelector((state) => state.app.types);

  React.useEffect(() => {
    dispatch(SET_APP(["step"], 1));
    dispatch(SET_APP(["campaign"], null));
    dispatch(GET_CAMPAIGNS());
  }, [dispatch]);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const items = () => {
    const available_statuses = [
      "NOT_STARTED",
      "DIALING",
      "ON_HOLD",
      "DELETED",
      "FINISHED",
      "ENDED",
    ];
    const filtered_data = _.filter(data, (o) => {
      return available_statuses.indexOf(o.campaign_status) !== -1;
    });
    if (search) {
      const filters = [
        "campaign_name",
        "a_number_aons_set_name",
        "operator_group_name",
        "campaign_status",
        "scenario_id",
      ];
      return _.filter(filtered_data, (o) => {
        let exists = false;
        for (let i = 0; i < filters.length; i++) {
          const input = filters[i];
          const item = o[input];
          if (item) {
            let str;
            if (input === "campaign_status") {
              if (statuses[item]) str = statuses[item].text.toLocaleLowerCase();
              else continue;
            } else if (input === "scenario_id") {
              const type = _.find(types, { value: parseInt(item) });
              if (type) str = type.text.toLocaleLowerCase();
              else continue;
            } else {
              str = _.toString(item).toLocaleLowerCase();
            }
            if (str && str.indexOf(search.toLocaleLowerCase()) !== -1) {
              exists = true;
              break;
            }
          }
        }
        return exists;
      });
    }
    return filtered_data;
  };

  const onChange = (e) => {
    const { value } = e.target;
    if (value === "active") {
      dispatch(GET_CAMPAIGNS());
    } else {
      dispatch(GET_DELETED_CAMPAIGNS());
    }
    setSelected(value);
  };

  return (
    <>
      <PageHeader
        title="Список кампании"
        subTitle={<SearchInput onChange={onSearch} />}
        extra={[
          <Dropdown.Button
            key="create"
            type="success"
            onClick={onCreate}
            overlay={menu}
          >
            Создать кампанию
          </Dropdown.Button>,
        ]}
      />
      <Card
        bordered={false}
        title={
          <Radio.Group value={selected} onChange={onChange}>
            <Radio.Button value="active">Активные/Завершенные</Radio.Button>
            <Radio.Button value="archive">Удаленные</Radio.Button>
          </Radio.Group>
        }
      >
        <Table dataSource={items()} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const SearchInput = (props) => (
  <Input
    placeholder="Поиск"
    suffix={<SearchOutlined className="search-icon" />}
    {...props}
  />
);

const mapStateToProps = (state) => ({
  data: state.app.campaigns,
  loading: state.app.campaignsLoading,
});

export default connect(mapStateToProps)(Screen);
