import _ from "lodash";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Время начала",
      dataIndex: "interval_begin",
    },
    {
      title: "Время окончания",
      dataIndex: "interval_end",
    },
    {
      title: "Дни недели",
      dataIndex: "interval_weekdays",
    },
    {
      title: "Действия",
      dataIndex: "actions",
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          if (a[dataIndex] && b[dataIndex])
            return a[dataIndex].length - b[dataIndex].length;
        };
      }
    });
  }

  return data;
};

export const days = [
  { order: 1, key: "MO", name: "Понедельник", short: "ПН" },
  { order: 2, key: "TU", name: "Вторник", short: "ВТ" },
  { order: 3, key: "WE", name: "Среда", short: "СР" },
  { order: 4, key: "TH", name: "Четверг", short: "ЧТ" },
  { order: 5, key: "FR", name: "Пятница", short: "ПТ" },
  { order: 6, key: "SA", name: "Суббота", short: "СБ" },
  { order: 7, key: "SU", name: "Воскресенье", short: "ВС" },
];
