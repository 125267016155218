import defaults from "@/defaults";
export { default as Step1 } from "./Step1";
export { default as Step2 } from "./Step2";

export const formatDataByCampaignType = (temp) => {
  const type_id = temp.type.value.toString();
  const format = "YYYY-MM-DD HH:mm";
  let scenario_args;
  if (type_id === defaults.types.TARGETCONTACT) {
    scenario_args = [
      temp.selectedAbonentAudios[0].announcement_file_id.toString(),
      temp.selectedOperatorAudios[0].announcement_file_id.toString(),
      temp.aons_o[0].a_number_aons_set_id.toString(),
    ];
  } else if (type_id === defaults.types.OPERATORS) {
    scenario_args = [
      temp.selectedAbonentAudios[0].announcement_file_id.toString(),
      temp.selectedOperatorAudios[0].announcement_file_id.toString(),
    ];
  } else if (
    type_id === defaults.types.AUTOINFORMATOR ||
    type_id === defaults.types.AUTOINFORMATOR_DTMF
  ) {
    scenario_args = [
      temp.selectedAbonentAudios[0].announcement_file_id.toString(),
    ];
  }
  let values = {
    calendar_interval_set_id: temp.selectedCalendars[0].id,
    a_number_aons_set_id: temp.aons_a[0].a_number_aons_set_id,
    a_number_aons_represent_method_id: 3,
    scenario_id: temp.type.value,
    scenario_args,
    queue_priority_number: 1,
    campaign_name: temp.campaign_name,
    customer_comment: temp.customer_comment || "",
    call_attempts: temp.call_attempts,
    record_audio: temp.recording === 1,
    from_date: temp.startAt.utc().format(format),
    to_date: temp.endAt.utc().format(format),
    status_id: temp.status_id,
    scenario: temp.scenario,
    dial_manually: false,
  };
  if (
    type_id === defaults.types.TARGETCONTACT ||
    type_id === defaults.types.OPERATORS
  ) {
    values = {
      ...values,
      dial_manually: true,
      operator_group_id: temp.groups[0].id,
      operator_interval_between_calls: temp.operator_interval_between_calls,
    };
  }
  return values;
};
