import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Input, Button, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table from "./Table";
import { SET_APP, GET_STATUSES } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading } = props;

  const onCreate = () => {
    history.push("/statuses/new");
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_APP(["status"], null));
    dispatch(GET_STATUSES());
  }, [dispatch]);

  const backUrl = localStorage.getItem("backUrl");

  return (
    <>
      <PageHeader
        title="Список статусов обзвона"
        subTitle={<SearchInput />}
        onBack={() => history.push(backUrl ? backUrl : "/campaings")}
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Создать новый список
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const SearchInput = () => (
  <Input
    placeholder="Поиск"
    suffix={<SearchOutlined className="search-icon" />}
  />
);

const mapStateToProps = (state) => ({
  data: state.app.statuses,
  loading: state.app.statusesLoading,
});

export default connect(mapStateToProps)(Screen);
