import _ from "lodash";

export const filterFormatter = (data, label) => {
  return data.map((item) => ({ value: item.id, text: item[label] }));
};

export const sortAlphaNumeric = (a, b) => {
  a = _.toString(a);
  b = _.toString(b);

  return a.localeCompare(b);
};
export const intToHHMMSS = (secondsCount) => {
  if(!secondsCount) return "н/д";
  let sec_num = parseInt(secondsCount, 10); // don't forget the second param
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours + ':' + minutes + ':' + seconds;
}
