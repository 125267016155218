import React from "react";
import { PageHeader, Col, Card } from "antd";
import { Step1 } from "./Steps";
import "./index.less";

const Screen = () => {
  return (
    <Col span={16}>
      <PageHeader title="Добавить список" />
      <Card bordered={false} className="create-container">
        <Step1 />
      </Card>
    </Col>
  );
};

export default Screen;
