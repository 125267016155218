import React from "react";
import {Table} from "antd";
import {columns} from "./campaignsData";
import {useDispatch} from "react-redux";

const TableComp = (props) => {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true
  });

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const dispatch = useDispatch();

  const options = {}

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.id}
      {...props}
      pagination={pagination}
      onChange={onChange}
      scroll={{
        x: true
      }}
    />
  );
}

export default TableComp;
