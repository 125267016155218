import React from "react";
import _ from "lodash";
import { useDispatch, connect, useSelector } from "react-redux";
import { Table, Button, notification, Tag } from "antd";
import { columns } from "./data";
import { PhoneOutlined, SyncOutlined } from "@ant-design/icons";
import { useActiveTimer } from "../hooks";
import { call } from "@/actions/axios";
import { SET_APP } from "@/actions/app";
import defaults from "@/defaults";

const TableComp = (props) => {
  const { campaign, user } = props;
  const { notCallable, operatorStatus } = props;
  const { data, request } = props;

  const [filters, setFilters] = React.useState(null);
  const [sorter, setSorter] = React.useState(null);

  useActiveTimer();

  const onChange = (__, filters, sorter) => {
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const active = useSelector((state) => state.app.active);

  const dispatch = useDispatch();

  const open = async (item) => {
    if (campaign && campaign.campaign_status !== "DIALING") {
      return notCallable();
    }
    if (operatorStatus === "offline") {
      return notCallable("operator");
    }
    dispatch(SET_APP(["dialingNow"], true))
    const { cust_id } = request;
    const { id } = campaign;
    const { external_id } = item;
    // const external_id = item.id;
    const { msisdn } = user;

    try {
      await dispatch(
        call({
          url: `/lists/${cust_id}/command`,
          method: "POST",
          data: {
            id_in_list: external_id,
            request_id: request.id,
            msisdn,
            campaign_id: id,
          },
        })
      );

      await localStorage.setItem("callTime", new Date().getTime());
      await dispatch(SET_APP(["campaign"], campaign));
      await dispatch(SET_APP(["request"], request));
      await dispatch(SET_APP(["active"], external_id));
    } catch (e) {
      let message = "Не удалось набрать абонента";
      const m = _.get(e, ["response", "data", "message"]);
      if (m) message = m;
      notification.error({
        message: "Ошибка",
        description: message,
      });
    }
  };

  const options = {
    dialing_status: {
      render: (val) => {
        const s = defaults.statuses(val);
        if (!s.status) return null;
        return s.status;
      },
    },
    actions: {
      render: (_, item) => {
        // console.log({item})
        if (active && active === item.id)
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Выполняется набор
            </Tag>
          );
        return (
          <div className="actions">
            <Button onClick={() => open(item)} size="small" type="action">
              <PhoneOutlined /> Позвонить
            </Button>
          </div>
        );
      },
    },
  };

  return (
    <>
      <Table
        size="small"
        className="striped-table"
        columns={columns(campaign)(options, filters, sorter)}
        rowKey={(row) => row.id}
        {...props}
        dataSource={data}
        pagination={false}
        onChange={onChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { armData } = state.app;
  const props = {
    user: state.auth.user,
    loading: state.app.armLoading,
  };

  if (armData) {
    if (armData.request) props.request = armData.request;
    if (armData.abonents) props.data = armData.abonents;
  }

  return props;
};

export default connect(mapStateToProps)(TableComp);
