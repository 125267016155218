import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { GET_ARM_LIST, SET_APP } from "@/actions/app";

export default function useFetchingRequest(campaign) {
  const [fetching, setFetching] = React.useState(false);
  const dialingNow = useSelector((state) => state.app.dialingNow);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if(dialingNow){
      // console.log("dont update arm list");
      return;
    }
    if(campaign && campaign.campaign_status === "ENDED") {
      // console.log("CAMPAIGN ENDED!")
      return;
    }
    const fetchData = async () => {
      if (!campaign || campaign.campaign_status !== "DIALING")
        return dispatch(SET_APP(["armData"], null));

      setFetching(true);

      if (fetching) return;

      await dispatch(GET_ARM_LIST(campaign.id));
      setFetching(false);
    };
    fetchData();
  }, [campaign]); // eslint-disable-line react-hooks/exhaustive-deps
}
