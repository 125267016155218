import React from "react";
import _ from "lodash";
import uniqid from "uniqid";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, notification, Form, Input } from "antd";
import { Row, Col, Select, InputNumber } from "antd";
import Picker from "./Picker";
import { Context } from "..";
import { Spinner } from "@/ui/Loading";
import { call } from "@/actions/axios";

import { GET_GROUPS, GET_AONS, GET_SCENARIOS } from "@/actions/app";
import { SET_APP } from "@/actions/app";
import defaults from "@/defaults";

const { Option } = Select;

const Step = (props) => {
  const customerInfo = useSelector((state) => state.auth.customerInfo);
  const cust_id = useSelector((state) => state.auth.cust_id);
  const context = React.useContext(Context);

  const dispatch = useDispatch();

  const { history, match } = props;
  const { types, priorities } = props;
  const { groups, groupsLoading } = props;
  const { aons, aonsLoading } = props;
  const { scenarios, scenariosLoading } = props;
  const { setStep, values, setValues } = context;

  const [loading, setLoading] = React.useState(false);

  const [selectedAbonentAons, setSelectedAbonentAons] = React.useState([]);
  const [selectedOperatorAons, setSelectedOperatorAons] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  const [type, setType] = React.useState(null);
  const [picker, setPicker] = React.useState(null);

  const form_ref = React.useRef();
  const aon_a_ref = React.useRef();
  const aon_o_ref = React.useRef();
  const group_ref = React.useRef();

  const isNew = match.params.id === "new";

  const onChange = (index, text) => {
    form_ref.current.setFieldsValue({ [index]: text });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const newValues = await form_ref.current.validateFields();
      newValues.id = uniqid();
      if (newValues.type) {
        newValues.type = _.find(types, { value: newValues.type.value });
      }
      if (newValues.priority) {
        newValues.priority = _.find(priorities, {
          value: newValues.priority.value,
        });
      }
      newValues.aons_a = selectedAbonentAons;
      newValues.aons_o = selectedOperatorAons;
      newValues.groups = selectedGroups;
      setLoading(false);
      setValues({ ...values, ...newValues });
      setStep(2);
    } catch (e) {
      setLoading(false);
      return notification.error({
        message: "Неизвестная ошибка!",
        description: "Не получается перейти на следующий шаг",
      });
    }
  };

  const serviceAccess = (type) => {
    // if (type.value !== 3) return true;
    return customerInfo.allowed_campaign_types.indexOf(type.value) === -1;
  };

  const openPicker = (picker) => () => {
    setPicker(picker);
    if (picker === "aon_a") aon_a_ref.current.blur();
    else if (picker === "aon_o") aon_o_ref.current.blur();
    else if (picker === "group") group_ref.current.blur();
  };

  let pickerProps = {
    columns: [
      { title: "Наименование АОН", dataIndex: "display_name" },
      { title: "Группа АОН", dataIndex: "a_number_aons_set_name" },
      { title: "Описание", dataIndex: "customer_comment" },
    ],
    data: aons,
    multiple: false,
    indexKey: "msisdn",
    displayIndex: "a_number_aons_set_name",
    setPicker,
  };
  if (picker === "aon_a") {
    pickerProps = {
      ...pickerProps,
      selected: selectedAbonentAons,
      setSelected: setSelectedAbonentAons,
    };
  } else if (picker === "aon_o") {
    pickerProps = {
      ...pickerProps,
      selected: selectedOperatorAons,
      setSelected: setSelectedOperatorAons,
    };
  } else if (picker === "group") {
    pickerProps = {
      ...pickerProps,
      columns: [
        { title: "Группа", dataIndex: "operator_group_name" },
        { title: "Описание", dataIndex: "customer_comment" },
      ],
      data: groups,
      indexKey: "id",
      displayIndex: "operator_group_name",
      selected: selectedGroups,
      setSelected: setSelectedGroups,
    };
  }

  const getScenario = async () => {
    try {
      const { data } = await dispatch(
        call({ url: `/scenarios/${cust_id}/${match.params.id}` })
      );
      if (data) form_ref.current.setFieldsValue({ scenario: data.ref_id });
    } catch (e) {}
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(GET_GROUPS());
      await dispatch(GET_AONS());
      await dispatch(GET_SCENARIOS());
      if (values && !isNew) {
        if (values.scenario_id) {
          const type = _.find(types, {
            value: values.scenario_id,
          });

          setType(type.value.toString());
          form_ref.current.setFieldsValue({ type });
          if (values.scenario_args) {
            const aon_id = values.scenario_args[2];
            if (aon_id) {
              const aon = _.find(aons, {
                a_number_aons_set_id: parseInt(aon_id),
              });
              if (aon) {
                form_ref.current.setFieldsValue({
                  aon_o: aon.a_number_aons_set_name,
                });
                setSelectedOperatorAons([aon]);
              }
            }
          }
        }

        if (values.a_number_aons_set_id) {
          const aon = _.find(aons, {
            a_number_aons_set_id: values.a_number_aons_set_id,
          });
          if (aon) {
            form_ref.current.setFieldsValue({
              aon_a: aon.a_number_aons_set_name,
            });
            setSelectedAbonentAons([aon]);
          }
        }
        if (values.operator_group_id) {
          const group = _.find(groups, {
            id: values.operator_group_id.toString(),
          });
          if (group) {
            form_ref.current.setFieldsValue({
              group: group.operator_group_name,
            });
            setSelectedGroups([group]);
          }
        }
        getScenario();
      }

      if (isNew) {
        setSelectedAbonentAons(values.aons_a);
        setSelectedOperatorAons(values.aons_o);
        setSelectedGroups(values.groups);
        if (values.type) setType(values.type.value.toString());
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeFields = (fields) => {
    if (fields.length > 0) {
      const [field] = fields;
      const [name] = field.name;
      if (name === "type") {
        setType(field.value.toString());
      }
    }
  };

  const cacheValues = async () => {
    let values = await form_ref.current.getFieldsValue();
    values.selectedAbonentAons = selectedAbonentAons;
    values.selectedOperatorAons = selectedOperatorAons;
    values.selectedGroups = selectedGroups;
    return values;
  };

  const onScenario = async () => {
    const newValues = await cacheValues();
    dispatch(SET_APP(["step"], 1));
    dispatch(SET_APP(["campaign"], { ...values, ...newValues }));
    let path = "/campaigns/";
    if (isNew) path += "new";
    else path += match.params.id;
    localStorage.setItem("backUrl", path);
    history.push("/scenarios");
  };

  const FORM_ITEM_DISABLE_BY_TYPE =
    type === defaults.types.AUTOINFORMATOR ||
    type === defaults.types.AUTOINFORMATOR_DTMF ||
    type === null;

  return (
    <>
      <Picker onChange={onChange} picker={picker} {...pickerProps} />

      <Form
        layout="vertical"
        ref={form_ref}
        initialValues={values}
        onFieldsChange={onChangeFields}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Тип кампании"
              name={["type", "value"]}
              rules={[{ required: true }]}
            >
              <Select disabled={loading || !isNew} placeholder="Выберите тип">
                {types.map((type) => (
                  <Option
                    key={type.value.toString()}
                    value={type.value}
                    disabled={serviceAccess(type)}
                  >
                    {type.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Название кампании"
              name="campaign_name"
              rules={[
                {
                  min: 2,
                  max: 1000,
                  required: true,
                  message: "Данное поле обязательно",
                },
              ]}
            >
              <Input
                disabled={loading}
                placeholder="Не менее 2 и не более 100 символов"
              />
            </Form.Item>
            {FORM_ITEM_DISABLE_BY_TYPE || (
              <Form.Item
                label="Группа операторов"
                name="group"
                rules={[{ required: true, message: "Данное поле обязательно" }]}
              >
                <Input
                  ref={group_ref}
                  disabled={loading || groupsLoading}
                  onFocus={openPicker("group")}
                  readOnly={true}
                  placeholder="Выберите группу операторов"
                  suffix={groupsLoading ? <Spinner /> : null}
                />
              </Form.Item>
            )}
            {FORM_ITEM_DISABLE_BY_TYPE || (
              <Form.Item
                label="Сценарий"
                name="scenario"
                help={<Scenario onScenario={onScenario} />}
              >
                <Select
                  disabled={loading || scenariosLoading}
                  placeholder="Выберите сценарий"
                  allowClear={true}
                >
                  {scenarios.map((scenario) => (
                    <Option key={scenario.id} value={scenario.id}>
                      {scenario.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label="АОН для абонента"
              name="aon_a"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                ref={aon_a_ref}
                disabled={loading || aonsLoading}
                onFocus={openPicker("aon_a")}
                readOnly={true}
                placeholder="Выберите АОН"
                suffix={aonsLoading ? <Spinner /> : null}
              />
            </Form.Item>
            <Form.Item
              label="Количество попыток дозвона"
              name="call_attempts"
              rules={[
                {
                  required: true,
                  message: "Данное поле обязательно",
                },
              ]}
            >
              <InputNumber
                min={1}
                max={5}
                disabled={loading}
                placeholder="Укажите количество"
              />
            </Form.Item>
            {FORM_ITEM_DISABLE_BY_TYPE || (
              <Form.Item
                label="Временной интервал между звонками, сек"
                name="operator_interval_between_calls"
                rules={[
                  {
                    required: true,
                    message: "Данное поле обязательно",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={600}
                  disabled={loading}
                  placeholder="Укажите временной интервал"
                />
              </Form.Item>
            )}
            {type === defaults.types.TARGETCONTACT && (
              <Form.Item
                label="АОН для оператора"
                name="aon_o"
                rules={[{ required: true, message: "Данное поле обязательно" }]}
              >
                <Input
                  ref={aon_o_ref}
                  disabled={loading || aonsLoading}
                  onFocus={openPicker("aon_o")}
                  readOnly={true}
                  placeholder="Выберите АОН"
                  suffix={aonsLoading ? <Spinner /> : null}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          Далее
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const Scenario = ({ onScenario }) => {
  return (
    <div>
      <Button className="info action" type="link" onClick={onScenario}>
        + добавить новый
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  types: state.app.types,
  priorities: state.app.priorities,

  groups: state.app.groups,
  groupsLoading: state.app.groupsLoading,

  aons: state.app.aons,
  aonsLoading: state.app.aonsLoading,

  lists: state.app.lists,
  listsLoading: state.app.listsLoading,

  scenarios: state.app.scenarios,
  scenariosLoading: state.app.scenariosLoading,
});

export default withRouter(connect(mapStateToProps)(Step));
