import { call } from "./axios";
import createAppStore from "@/store";

export const SET_AUTH = (path, value) => ({
  type: "SET_AUTH",
  path,
  value,
});

export const LOGOUT = () => {
  return (dispatch) => {
    const { persistor } = createAppStore();
    dispatch({ type: "INIT" });
    dispatch({ type: "LOGOUT" });
    localStorage.clear();
    persistor.purge();
  };
};

export const CHECK_ACCESS = (customer, msisdn, history) => async (dispatch) => {
  const services = Object.keys(customer.services);
  if (services.indexOf("autodialer") === -1) {
    dispatch(
      SET_AUTH(["message"], `Не подключена услуга "Автодайлер Кабинет"`)
    );
    return false;
  }
  const role = customer.services.autodialer.profile;
  let cust_id;
  if (["operator", "supervisor"].indexOf(role) === -1) {
    dispatch(SET_AUTH(["message"], "Не закреплена роль к данному сервису"));
    return false;
  }
  dispatch(SET_AUTH(["role"], role));
  try {
    const customerInfoResponse = await dispatch(
      call({
        url: `api/admin/customers?bin=${customer.idDocNum}`,
      })
    );
    cust_id = customerInfoResponse.data.cust_id;
    dispatch(SET_AUTH(["cust_id"], cust_id));
    dispatch(SET_AUTH(["message"], null));
  } catch (e) {
    dispatch(SET_AUTH(["message"], "кaмпания не подключена к услуге"));
    history.push("access");
    return false;
  }
  try {
    const custBasicInfo = await dispatch(
      call({
        url: `api/admin/customers/basic/${cust_id}`,
      })
    );
    const { service_active } = custBasicInfo.data;
    if (!service_active) {
      dispatch(
        SET_AUTH(["message"], "Доступ к кабинету отключен для вашей кaмпании")
      );
      history.push("access");
      return false;
    }
  } catch (e) {}
  try {
    const custDetailedInfo = await dispatch(
      call({
        url: `api/admin/customers/detailed/${cust_id}`,
      })
    );
    dispatch(SET_AUTH(["customerInfo"], custDetailedInfo.data));
    dispatch(SET_AUTH(["message"], null));
  } catch (e) {
    dispatch(SET_AUTH(["message"], "кaмпания настроена некорректно"));
    history.push("access");
    return false;
  }
  try {
    dispatch(GET_USER_SIP(cust_id, msisdn));
  } catch (e) {
    // console.log(e.message);
    dispatch(SET_AUTH(["message"], "Не найден пользователь"));
    history.push("access");
    return false;
  }
  return true;
};

export const GET_USER_SIP = (cust_id, msisdn) => async (dispatch) => {
  const { data } = await dispatch(
    call({
      url: `api/customer/operators/msisdns/assigned/${cust_id}/${msisdn}`,
    })
  );
  const { [msisdn]: user } = data.msisdn;
  dispatch(SET_AUTH(["api_user"], user));
};
