import React from "react";
import _ from "lodash";
import { Select } from "antd";
import { connect } from "react-redux";

const { Option } = Select;

const SelectList = (props) => {
  const { loading, data } = props;
  const { setContacts, setList } = props;

  const onSelect = (id) => {
    const item = _.find(data, { id });
    setContacts(item.targetlist_abonents);
    setList(id);
  };

  return (
    <Select
      style={{ width: "100%" }}
      disabled={loading}
      placeholder="Выберите список"
      onSelect={onSelect}
    >
      {data.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.title} (Контактов - {item.count})
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.lists,
  loading: state.app.listsLoading,
});

export default connect(mapStateToProps)(SelectList);
