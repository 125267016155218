import React from "react";
import { Modal, Table } from "antd";

const Picker = (props) => {
  const { onChange, picker, setPicker } = props;
  const { selected, setSelected, data, multiple } = props;
  const { columns, label, indexKey, displayIndex } = props;

  const onSelect = () => {
    // console.log(selectedRows);
    if (selectedRows.length !== 0) {
      let text = `Выбрано ${label}: ${selectedRows.length}`;
      if (!multiple) {
        const [item] = selectedRows;
        text = item[displayIndex];
      }
      onChange(picker, text);
      setSelected(selectedRows);
      setSelectedRows([]);
    }
    setPicker(null);
  };

  const [selectedRows, setSelectedRows] = React.useState(selected || []);

  React.useEffect(() => {
    setSelectedRows(selected);
  }, [selected]);

  return (
    <Modal
      title="Выберите из списка"
      visible={!!picker}
      onOk={onSelect}
      onCancel={() => setPicker(null)}
    >
      {!!picker && (
        <Table
          size="small"
          dataSource={data}
          columns={columns}
          rowKey={(row) => row[indexKey]}
          pagination={false}
          rowSelection={{
            type: multiple ? "checkbox" : "radio",
            selectedRowKeys:
              selectedRows && selectedRows.map((s) => s[indexKey]),
            onChange: (__, selectedRows) => {
              setSelectedRows(selectedRows);
            },
          }}
        />
      )}
    </Modal>
  );
};

export default Picker;
