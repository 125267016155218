import _ from "lodash";
import {sortAlphaNumeric} from "../../../utils";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Оператор/Группа",
      dataIndex: "operatorNameAndGroup",
      sorter: true,
    },
    {
      title: "Id/Кампания",
      dataIndex: "campaignIdAndName",
      sorter: true,
    },
    {
      title: "Дата и время начала кампании",
      dataIndex: "campaignFromDate",
      sorter: true,
      sorterType: "string"
    },
    {
      title: "Всего номеров в кампании",
      dataIndex: "campaignContactsTotalCount",
      sorter: true,
    },
    {
      title: "Всего успешных вызовов",
      dataIndex: "campaignContactsAnsweredCount",
      sorter: true,
    },
    {
      title: "% успешных вызовов",
      dataIndex: "answeredToTotalRate",
      sorter: true,
    },
    {
      title: "Всего вызовов",
      dataIndex: "campaignContactsDialedCount",
      sorter: true,
    },
    {
      title: "% успешных вызовов от всего вызовов",
      dataIndex: "answeredToDialedCount",
      sorter: true,
    },
    {
      title: "Статус оператора",
      dataIndex: "operatorPresence",
      sorter: true,
    },
    {
      title: "Общее время разговора",
      dataIndex: "conversationTotalTime",
      sorter: true,
    },
    {
      title: "Средн. время разговора",
      dataIndex: "conversationAverageTimePerCall",
      sorter: true,
    },
    {
      title: "Общее время ожидания",
      dataIndex: "dialingTotalTime",
      sorter: true,
    },
    {
      title: "Среднее время ожидания",
      dataIndex: "dialingAverageTimePerCall",
      sorter: true,
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          return sortAlphaNumeric(a[dataIndex], b[dataIndex]);
        };
      }
    });
  }

  return data;
}
