import React from "react";
import _ from "lodash";
import uniqid from "uniqid";
import moment from "moment";
import { Table, Button, Form, TimePicker } from "antd";
import { Select, Modal } from "antd";
import { columns, days } from "./data";
import {
  DeleteOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = TimePicker;

const format = "HH:mm";

const Intervals = (props) => {
  const { data, setIntervals } = props;
  const form = React.useRef();
  const [adding, setAdding] = React.useState(false);
  const [editing, setEditing] = React.useState(null);
  const [items, setItems] = React.useState([]);
  React.useEffect(() => {
    if (data) {
      const temp = [];
      Object.keys(data).forEach((key) => temp.push({ ...data[key], id: key }));
      setItems(temp);
    }
  }, [data]);

  const onEdit = (item) => {
    setAdding(true);
    setEditing(item);
    form.current.setFieldsValue({
      intervals: [
        moment.utc(item.interval_begin, format).local(),
        moment.utc(item.interval_end, format).local(),
      ],
      ...item,
    });
  };

  const onDelete = (item) => {
    const newItems = _.filter(items, (v) => v.id !== item.id);
    // console.log(newItems, item);
    item.hide = true;
    setIntervals([...newItems, item]);
  };

  const options = {
    interval_begin: {
      render: (time) => moment.utc(time, format).local().format(format),
    },
    interval_end: {
      render: (time) => moment.utc(time, format).local().format(format),
    },
    interval_weekdays: {
      render: (weekdays) => {
        const WD = weekdays.map((key) => _.find(days, { key }));
        return _.sortBy(WD, ["order"])
          .map((day) => day.short)
          .join(", ");
      },
    },
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            <Button
              onClick={() => onEdit(item)}
              size="small"
              type="action_edit"
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => onDelete(item)}
              size="small"
              type="action_delete"
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  };

  const onAdd = async () => {
    const values = await form.current.validateFields();
    const [start, end] = values.intervals;

    let WD = values.interval_weekdays.map((key) => _.find(days, { key }));
    WD = _.sortBy(WD, ["order"]).map((day) => day.key);

    const item = {
      interval_begin: start.utc().format(format),
      interval_end: end.utc().format(format),
      interval_weekdays: WD,
    };

    if (editing) {
      const index = _.findIndex(items, { id: editing.id });
      items[index] = { ...editing, ...item };
      setIntervals([...items]);
    } else {
      item.id = uniqid();
      setIntervals([...items, item]);
    }
    setAdding(false);
    setEditing(null);
    form.current.resetFields();
  };

  const onCancel = () => {
    setAdding(false);
    setEditing(null);
    form.current.resetFields();
  };

  return (
    <>
      <Modal
        title="Новый интервал"
        visible={adding}
        onOk={onAdd}
        forceRender={true}
        onCancel={onCancel}
      >
        <Form layout="vertical" ref={form}>
          <Form.Item
            label="Укажите время"
            name="intervals"
            rules={[{ required: true, message: "Данное поле обязательно" }]}
          >
            <RangePicker
              style={{ width: "100%" }}
              minuteStep={15}
              secondStep={10}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item
            label="Время окончания"
            name="interval_weekdays"
            rules={[{ required: true, message: "Данное поле обязательно" }]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Выберите дни недели"
            >
              {days.map((day) => (
                <Option key={day.key}>{day.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => setAdding(true)}>
          <CalendarOutlined />+ Добавить интервал
        </Button>
      </div>
      <Table
        size="small"
        className="striped-table"
        columns={columns(options)}
        rowKey={(row) => row.id}
        dataSource={_.filter(items, (o) => !o.hide)}
        pagination={null}
      />
    </>
  );
};

export default Intervals;
