import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Layout, Menu } from "antd";
import { connect } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import "./index.less";

const { Header } = Layout;

const H = (props) => {
  const [keycloak, initialized] = useKeycloak();
  if (!initialized || !keycloak.token) return <div />;

  const { pathname } = props.location;

  const hideHeader = ["/", "/access"];

  if (hideHeader.indexOf(pathname) !== -1) return <div />;

  let selectedKeys = "/" + pathname.split("/")[1];
  if (selectedKeys === "/groups") selectedKeys = "/operators";
  else if (
    selectedKeys === "/scenarios" ||
    selectedKeys === "/calendars" ||
    selectedKeys === "/statuses" ||
    selectedKeys === "/preview"
  )
    selectedKeys = "/campaigns";
  else if (selectedKeys === "/agroups") selectedKeys = "/aons";

  const { role, customerInfo } = props;

  const supervisor = role === "supervisor";

  return (
    <Header className="main__header">
      <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKeys]}>
        {supervisor && (
          <Menu.Item key="/campaigns">
            <NavLink to="/campaigns">Кампании</NavLink>
          </Menu.Item>
        )}
        {supervisor && (
          <Menu.Item key="/operators">
            <NavLink to="/operators">Операторы</NavLink>
          </Menu.Item>
        )}
        {supervisor && (
          <Menu.Item key="/audios">
            <NavLink to="/audios">Аудиоролики</NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="/arm">
          <NavLink to="/arm">APM Оператора</NavLink>
        </Menu.Item>
        <Menu.Item key="/reports">
          <NavLink to="/reports">Мониторинг</NavLink>
        </Menu.Item>
        <Menu.Item key="/stats">
          <NavLink to="/stats">Отчеты</NavLink>
        </Menu.Item>
        {supervisor && (
          <Menu.Item
            disabled={customerInfo.crm_integration_allowed !== 1}
            key="/integrations"
          >
            <NavLink to="/integrations">Интеграция</NavLink>
          </Menu.Item>
        )}
      </Menu>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  customerInfo: state.auth.customerInfo,
});

export default withRouter(connect(mapStateToProps)(H));
