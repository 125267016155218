import React from "react";
import { Tooltip, Button } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  // HistoryOutlined,
  BorderOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { statuses } from "./data";

const Actions = (props) => {
  const { item, onEdit, onDelete, onStatus, loading, uploadContacts } = props;
  const { campaign_status: status } = item;
  const PREVIEW = (
    <Tooltip placement="topLeft" title={statuses.PREVIEW.action}>
      <Button
        onClick={() => onEdit(item, "preview")}
        size="small"
        type="action_edit"
        disabled={loading}
      >
        <EyeOutlined />
      </Button>
    </Tooltip>
  );
  // const PAUSED = (
  //   <Tooltip placement="topLeft" title={statuses.PAUSED.action}>
  //     <Button
  //       onClick={onStatus(item, "PAUSED")}
  //       size="small"
  //       type="action_pause"
  //       disabled={loading}
  //     >
  //       <PauseCircleOutlined />
  //     </Button>
  //   </Tooltip>
  // );
  const ENDED = (
    <Tooltip placement="topLeft" title={statuses.ENDED.action}>
      <Button
        disabled={loading}
        onClick={onStatus(item, "ENDED")}
        // onClick={onStatus(item, "FINISHED")}
        size="small"
        type="action_stop"
      >
        <BorderOutlined />
      </Button>
    </Tooltip>
  );
  const EDIT = (
    <Tooltip placement="topLeft" title={statuses.EDIT.action}>
      <Button
        disabled={loading}
        onClick={() => onEdit(item, "edit")}
        size="small"
        type="action_edit"
      >
        <EditOutlined />
      </Button>
    </Tooltip>
  );

  const ADD_CONTACTS = (
    <Tooltip placement="topLeft" title={statuses.ADD_CONTACTS.action}>
      <Button
        disabled={loading}
        onClick={uploadContacts(item)}
        size="small"
        type="action"
      >
        <FileAddOutlined />
      </Button>
    </Tooltip>
  );

  const DELETE = (
    <Tooltip placement="topLeft" title={statuses.DELETE.action}>
      <Button
        onClick={() => onDelete(item)}
        disabled={loading}
        size="small"
        type="action_delete"
      >
        <DeleteOutlined />
      </Button>
    </Tooltip>
  );

  const PLAY = (
    <Tooltip placement="topLeft" title={statuses.DIALING.action}>
      <Button
        disabled={loading}
        onClick={onStatus(item, "DIALING")}
        size="small"
        type="action"
      >
        <PlayCircleOutlined />
      </Button>
    </Tooltip>
  );

  const ON_HOLD = (
    <Tooltip placement="topLeft" title={statuses.ON_HOLD.action}>
      <Button
        onClick={onStatus(item, "ON_HOLD")}
        disabled={loading}
        size="small"
        type="action_edit"
      >
        {/* <HistoryOutlined /> */}
        <PauseCircleOutlined />
      </Button>
    </Tooltip>
  );

  if (
    item.contacts_count === 0 &&
    item.campaign_status !== "" &&
    item.campaign_status !== "DELETED"
  ) {
    return (
      <>
        {PREVIEW}
        {ADD_CONTACTS}
        {EDIT}
        {DELETE}
      </>
    );
  } else if (status === "NOT_STARTED") {
    return (
      <>
        {PREVIEW}
        {PLAY}
        {EDIT}
        {DELETE}
      </>
    );
  } else if (status === "PAUSED") {
    return (
      <>
        {PREVIEW}
        {PLAY}
        {EDIT}
        {ENDED}
      </>
    );
  } else if (status === "DIALING") {
    return (
      <>
        {PREVIEW}
        {ON_HOLD}
        {EDIT}
      </>
    );
  } else if (status === "ON_HOLD") {
    return (
      <>
        {PREVIEW}
        {PLAY}
        {EDIT}
        {ENDED}
      </>
    );
  } else if (status === "FINISHED") return <>{PREVIEW}</>;
  else if (status === "ENDED") return <>{PREVIEW}</>;
  else if (status === "DELETED") return <>{PREVIEW}</>;
  return null;
};

export default Actions;
