import React from "react";
import _ from "lodash";
import uniqid from "uniqid";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import { Col, Row, Tooltip } from "antd";
import { call } from "@/actions/axios";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const Step = (props) => {
  const { history, match, cust_id } = props;
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [options, setOptions] = React.useState([{ id: uniqid(), value: "" }]);

  React.useEffect(() => {
    const { data, match } = props;
    const item = _.find(data, { id: parseInt(match.params.id) });
    if (item) {
      form.current.setFieldsValue(item);
      if (item.data) {
        setOptions(item.data);
        if (item.data.length > 0) {
          let text = `Добавлено: ${item.data.length}`;
          form.current.setFieldsValue({ option: text });
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  const isNew = match.params.id === "new";

  const onSubmit = async () => {
    const { match } = props;
    const values = await form.current.validateFields();

    values.options = options;
    setLoading(true);
    if (isNew) {
      await dispatch(
        call({
          url: "/statuses/" + cust_id,
          method: "POST",
          data: values,
        })
      );
    } else {
      await dispatch(
        call({
          url: `/statuses/${cust_id}/${match.params.id}`,
          method: "PATCH",
          data: values,
        })
      );
    }
    setLoading(false);

    confirm({
      title: "Список успешно " + (isNew ? "добавлен" : "обновлен"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push("/statuses");
      },
    });
  };

  const option = React.useRef();

  const openPicker = () => {
    option.current.blur();
    setVisible(true);
  };

  const onChange = (text) => {
    form.current.setFieldsValue({ option: text });
  };

  return (
    <>
      <Picker
        visible={visible}
        setVisible={setVisible}
        onChange={onChange}
        options={options}
        setOptions={setOptions}
      />
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Наименование"
              name="title"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                disabled={loading}
                placeholder="Не менее 2 и не более 100 символов"
              />
            </Form.Item>
            <Form.Item
              label="Варианты ответов"
              name="option"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                ref={option}
                disabled={loading}
                readOnly={true}
                onFocus={openPicker}
                placeholder="Добавьте минимум 1 ответ"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const Options = (props) => {
  return (
    <div>
      <Button className="info action" type="link" {...props}>
        + добавить новый
      </Button>
    </div>
  );
};

const Picker = (props) => {
  const { visible, setVisible } = props;
  const { onChange, options, setOptions } = props;

  const onSelect = () => {
    let values = form.current.getFieldsValue();
    values = Object.values(values);
    let text = "";
    if (values.length > 1) {
      values = _.filter(values, (v) => !!v.value);
      text = `Добавлено статусов: ${values.length}`;
    } else {
      const [{ value }] = values;
      if (value) text = `Добавлено статусов: ${values.length}`;
    }
    setOptions([...values]);

    onChange(text);
    setVisible(false);
  };

  const onDelete = (index) => () => {
    let values = form.current.getFieldsValue();
    values = Object.values(values);
    values.splice(index, 1);
    setOptions([...values]);
  };

  const form = React.useRef();
  const o = React.useRef();

  const newOption = () => {
    const item = { id: uniqid(), value: "" };
    const values = form.current.getFieldsValue();
    setOptions([...Object.values(values), item]);
  };

  React.useEffect(() => {
    if (form.current) form.current.setFieldsValue(options);
  }, [options]);

  return (
    <Modal
      title="Статусы"
      visible={visible}
      onOk={onSelect}
      onCancel={() => setVisible(false)}
    >
      <Form ref={form} initialValues={options}>
        {options.map((__, index) => (
          <div key={index}>
            <Form.Item name={[index, "id"]} noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name={[index, "value"]}
              help={
                index === options.length - 1 ? (
                  <Options onClick={newOption} />
                ) : null
              }
            >
              <Input
                placeholder="Не менее 2 и не более 100 символов"
                ref={o}
                addonAfter={
                  index === options.length - 1 ? null : (
                    <Tooltip title="Удалить">
                      <DeleteOutlined
                        onClick={onDelete(index)}
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  )
                }
              />
            </Form.Item>
          </div>
        ))}
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.statuses,
  cust_id: state.auth.cust_id,
});

export default withRouter(connect(mapStateToProps)(Step));
