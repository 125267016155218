import _ from "lodash";
import { sortAlphaNumeric } from "@/utils";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Имя оператора",
      dataIndex: "represented_name",
      sorter: true,
      sorterType: "string",
    },
    {
      title: "Номер",
      dataIndex: "msisdn",
      sorter: true,
      sorterType: "string",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      sorterType: "string",
    },
    {
      title: "Роль",
      dataIndex: "role",
      sorter: true,
      sorterType: "string",
    },
    {
      title: "Группа",
      dataIndex: "operator_group_name",
      sorter: true,
      sorterType: "string",
    },
    {
      title: "Действия",
      dataIndex: "actions",
      width: 150,
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          return sortAlphaNumeric(a[dataIndex], b[dataIndex]);
        };
      }
    });
  }

  return data;
};
