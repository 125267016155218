import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, notification } from "antd";
import { columns } from "./data";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { REMOVE_APP_BY_PARAM } from "@/actions/app";
import { call } from "@/actions/axios";

const { confirm } = Modal;

const TableComp = (props) => {
  const cust_id = useSelector((state) => state.auth.cust_id);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const onEdit = (id) => {
    props.history.push("/calendars/" + id);
  };

  const dispatch = useDispatch();

  const onDelete = (id) => {
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: "Вы действительно хотите удалить календарный интервал?",
      okText: "Да",
      async onOk() {
        try {
          await dispatch(
            call({
              url: `/api/customer/calendars/private/sets/${cust_id}/${id}`,
              method: "DELETE",
            })
          );
          dispatch(REMOVE_APP_BY_PARAM(["calendars"], "id", id));
        } catch (e) {
          return notification.info({
            message: "Ошибка!",
            description: "Не удалось удалить календарный интервал",
          });
        }
      },
      onCancel() {},
    });
  };

  const options = {
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            <Button
              onClick={() => onEdit(item.id)}
              size="small"
              type="action_edit"
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => onDelete(item.id)}
              size="small"
              type="action_delete"
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  };

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.id}
      {...props}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default TableComp;
