import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Card, Tag, Modal } from "antd";
import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import Table from "./Table";
import { useFetchingSip, useFetchingStatus } from "./hooks/";
import { useFetchingCampaign, useFetchingRequest } from "./hooks/";
import { useActiveConversation } from "./hooks/";
import Socket from "@/ui/Socket";
import { SET_APP } from "@/actions/app";
import "./index.less";
import {SET_APP_BY_PARAM} from "../../actions/app";

const Screen = (props) => {
  const { history } = props;
  const { user, api_user, cust_id } = props;

  const { msisdn } = user;
  const { id_of_campaign_assigned_to } = api_user;

  useFetchingSip(cust_id, msisdn);
  const [status, setStatus] = useFetchingStatus(cust_id, msisdn);
  const { campaign, ready } = useFetchingCampaign(api_user);
  useFetchingRequest(campaign);
  useActiveConversation(history);

  const socket = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const id_of_campaign_assigned_to = campaign ? campaign.id : null;
    // console.log({campaign});
    // console.log('compain ID:',{id_of_campaign_assigned_to})
    if(!campaign || !id_of_campaign_assigned_to) return;
    socket.current = Socket("arm");
    socket.current.on("update-operator", (data) => {
      if (!data || !data.operator_msisdn || !data.presence_status) return;
      clearTimeout(window.requestTimer);
      window.requestTimer = setTimeout(() => {
        if (data.operator_msisdn === msisdn) {
          if (["online", "offline"].indexOf(data.presence_status) !== -1)
            setStatus(data.presence_status);
        }
      }, 1000);
    });
    socket.current.on("update-campaign", ({ campaign_id }) => {
      // console.log("update-campaign", campaign_id);
      // const {api_user:{id_of_campaign_assigned_to}} = props;
      // console.log('comp',{id_of_campaign_assigned_to});
      if (!campaign_id || !id_of_campaign_assigned_to) return;
      else if (
        id_of_campaign_assigned_to &&
        campaign_id.toString() === id_of_campaign_assigned_to.toString()
      ) {
        clearTimeout(window.requestTimer);
        window.requestTimer = setTimeout(() => {
          dispatch(SET_APP(["campaign"], null));
        }, 1000);
      }
    });

    socket.current.on("update-abonent", async (abonent) => {
      const { campaign_id } = abonent;
      const {api_user:{id_of_campaign_assigned_to}} = props;
      // console.log('abon',{id_of_campaign_assigned_to});
      // console.log("msg abon", {campaign_id}, {id_of_campaign_assigned_to});
      if (!campaign_id || !id_of_campaign_assigned_to) return;
      else if (
        campaign_id.toString() === id_of_campaign_assigned_to.toString()
      ) {
        const { id, dialing_status, available_for_dial, request_id, isASUpdate, isDSUpdate } = abonent;
        // console.log('got message...', {dialing_status}, {available_for_dial});
        if (dialing_status === "CONVERSATION") {
          localStorage.setItem("callTime", null);
          clearInterval(window.requestTimer);
          history.push("/arm/call/" + id);
        }
        if (isDSUpdate && dialing_status === "DIALING") {
          dispatch(SET_APP(["dialingNow"], true))
        }
        if (isDSUpdate && dialing_status !== "DIALING") {
          dispatch(SET_APP(["dialingNow"], false))
        }
        if (isASUpdate && available_for_dial && available_for_dial !== false) {
          // console.log('touched/....')
          dispatch(SET_APP(["armData"], null));
        }
      }
    });

    return () => {
      if (socket.current) socket.current.disconnect();
    };
  }, [campaign]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCampaignTitle = () => {
    if (campaign && campaign.campaign_name) return campaign.campaign_name;
    return null;
  };

  const modal = React.useRef();

  React.useEffect(() => {
    if (
      (!campaign && ready) ||
      (campaign && campaign.campaign_status !== "DIALING")
    ) {
      if (!modal.current) {
        notCallable();
      }
    } else {
      if (modal.current) {
        modal.current.destroy();
        modal.current = null;
      }
    }
  }, [campaign, ready]);

  const notCallable = (reason = "campaign") => {
    let props = {
      title: "Кампания не запущена",
      content: "Дождитесь пока супервайзер запустит кампанию на обзвон",
    };
    if (reason === "operator") {
      props = {
        title: "SIP в статусе оффлайн",
        content: "Оператор должен находиться в состоянии \"готов\"",
      };
    }
    modal.current = Modal.warning({
      ...props,
      okText: "Закрыть",
      onOk: () => {
        modal.current.destroy();
      },
    });
  };

  // const test = ()=>{
  //   const { abonents } = props.armData;
  //   console.log(abonents);
  //   const abIndex = abonents.findIndex(i=>i.id = 1);
  //   abonents[abIndex] = {...abonents[abIndex], dialing_status: "BUSY"};
  //   console.log(abonents)
  //   // history.push("/arm/call/1");
  //   dispatch(SET_APP(["armData"], null));
  // }

  return (
    <>
      <PageHeader
        title={getCampaignTitle()}
        subTitle={<CampaignStatus campaign={campaign} />}
        extra={[<Status key="status" status={status} />]}
      />
      {/*<div>ASSIGNED TO: {id_of_campaign_assigned_to}</div>*/}
      {/*<div onClick={()=>test()}>TEST</div>*/}
      <Card bordered={false}>
        <Table
          campaign={campaign}
          operatorStatus={status}
          notCallable={notCallable}
          history={history}
        />
      </Card>
    </>
  );
};

const CampaignStatus = ({ campaign }) => {
  if (!campaign) return null;

  if (campaign.campaign_status === "DIALING") {
    return <Tag color="success">Кампания запущена</Tag>;
  } else {
    return <Tag color="error">Кампания неактивна</Tag>;
  }
};

const Status = ({ status }) => {
  if (status === "online")
    return (
      <Tag icon={<CheckOutlined />} color="success">
        Оператор готов
      </Tag>
    );
  else if (status === "offline")
    return (
      <Tag icon={<WarningOutlined />} color="warning">
        Оператор не готов
      </Tag>
    );
  return null;
};

const mapStateToProps = (state) => ({
  cust_id: state.auth.cust_id,
  api_user: state.auth.api_user,
  user: state.auth.user,
  armData: state.app.armData
});

export default connect(mapStateToProps)(Screen);
