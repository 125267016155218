import React from "react";
import _ from "lodash";
import { PageHeader, Card, Descriptions } from "antd";
import { Row, Col, Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { GET_GROUPS, GET_AONS, GET_AUDIOS, SET_APP } from "@/actions/app";
import { call } from "@/actions/axios";
import defaults from "@/defaults";
import "./index.less";

const Screen = (props) => {
  const { history, match } = props;

  const [scenario, setScenario] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  const campaign = useSelector((state) => state.app.campaign);
  const types = useSelector((state) => state.app.types);
  const cust_id = useSelector((state) => state.auth.cust_id);
  const aons = useSelector((state) => state.app.aons);
  const audios = useSelector((state) => state.app.audios);

  const dispatch = useDispatch();

  React.useState(() => {
    if (!campaign) history.push("/campaigns");
    const getScenario = async () => {
      try {
        const { data } = await dispatch(
          call({ url: `/scenarios/${cust_id}/${match.params.id}` })
        );
        if (data) setScenario(data.name);
      } catch (e) {}
    };
    const getStatus = async () => {
      try {
        const { data } = await dispatch(
          call({ url: `/statuses/${cust_id}/${match.params.id}` })
        );
        if (data) setStatus(data.title);
      } catch (e) {}
    };
    const fetchData = async () => {
      await dispatch(GET_GROUPS());
      await dispatch(GET_AONS());
      await dispatch(GET_AUDIOS());
      await getScenario();
      await getStatus();
    };
    fetchData();
    return () => {
      dispatch(SET_APP(["campaign"], null));
    };
  }, []);

  if (!campaign) return null;

  const format = "DD.MM.YYYY HH:mm";

  const { record_audio, customer_comment, operator_group_name } = campaign;
  const { campaign_name, calendar_interval_set_name, scenario_id } = campaign;
  const { call_attempts, operator_interval_between_calls } = campaign;
  const { a_number_aons_set_name, scenario_args } = campaign;
  let { from_date, to_date } = campaign;

  from_date = moment.utc(from_date);
  if (from_date.isValid()) from_date = from_date.local().format(format);
  to_date = moment.utc(to_date);
  if (to_date.isValid()) to_date = to_date.local().format(format);

  const type = _.find(types, {
    value: scenario_id,
  });

  const aon_id = scenario_args[2];
  let aon;
  if (aon_id) {
    aon = _.find(aons, {
      a_number_aons_set_id: parseInt(aon_id),
    });
    if (aon) {
      aon = aon.a_number_aons_set_name;
    }
  }

  const [audio_a_id, audio_o_id] = scenario_args;
  let audio_a = _.find(audios, {
    announcement_file_id: audio_a_id,
  });
  let audio_o = _.find(audios, {
    announcement_file_id: audio_o_id,
  });
  if (audio_a) {
    audio_a = audio_a.name;
  }
  if (audio_o) {
    audio_o = audio_o.name;
  }

  // console.log(type);

  const FORM_ITEM_DISABLE_BY_TYPE =
    type.value.toString() === defaults.types.AUTOINFORMATOR ||
    type.value.toString() === defaults.types.AUTOINFORMATOR_DTMF ||
    type === null;

  return (
    <>
      <PageHeader
        title="Просмотр кампании"
        onBack={() => history.push("/campaigns")}
      />
      <Row>
        <Col span={20}>
          <Card bordered={false} className="create-container preview">
            <Descriptions title="Шаг 1" bordered size="small" column={2}>
              <Descriptions.Item label="Тип кампании">
                {type.text}
              </Descriptions.Item>
              <Descriptions.Item label="АОН для абонента">
                {a_number_aons_set_name}
              </Descriptions.Item>
              <Descriptions.Item label="Название кампании">
                {campaign_name}
              </Descriptions.Item>
              <Descriptions.Item label="Количество попыток дозвона">
                {call_attempts}
              </Descriptions.Item>
              {FORM_ITEM_DISABLE_BY_TYPE || (
                <Descriptions.Item label="Группа операторов">
                  {operator_group_name}
                </Descriptions.Item>
              )}
              {FORM_ITEM_DISABLE_BY_TYPE || (
                <Descriptions.Item label="Временной интервал между звонками, сек">
                  {operator_interval_between_calls}
                </Descriptions.Item>
              )}
              {FORM_ITEM_DISABLE_BY_TYPE || (
                <Descriptions.Item label="Сценарий">
                  {scenario}
                </Descriptions.Item>
              )}
              {type.value.toString() === defaults.types.TARGETCONTACT && (
                <Descriptions.Item label="АОН для оператора">
                  {aon}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Divider />
            <Descriptions title="Шаг 2" bordered size="small" column={2}>
              <Descriptions.Item label="Календарные интервалы">
                {calendar_interval_set_name}
              </Descriptions.Item>
              <Descriptions.Item label="Описание">
                {customer_comment}
              </Descriptions.Item>
              <Descriptions.Item label="Звуковое приветствие для абонента">
                {audio_a}
              </Descriptions.Item>
              <Descriptions.Item label="Период действия обзвона от">
                {from_date}
              </Descriptions.Item>
              {FORM_ITEM_DISABLE_BY_TYPE || (
                <Descriptions.Item label="Звуковое приветствие для оператора">
                  {audio_o}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Период действия обзвона по">
                {to_date}
              </Descriptions.Item>
              {FORM_ITEM_DISABLE_BY_TYPE || (
                <Descriptions.Item label="Список статусов">
                  {status}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Запись разговора">
                {record_audio ? "Включить" : "Выключить"}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Screen;
