import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button, Modal, notification, Tag } from "antd";
import { columns } from "./data";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import {
  WarningOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { call } from "@/actions/axios";
import { REMOVE_APP_BY_PARAM } from "@/actions/app";
import defaults from "@/defaults";

const { confirm } = Modal;

const TableComp = (props) => {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const audio = React.useRef();

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const onEdit = (id) => {
    props.history.push("/audios/" + id);
  };

  const dispatch = useDispatch();

  const onDelete = (id) => {
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: "Вы действительно хотите удалить аудиоролик?",
      okText: "Да",
      async onOk() {
        try {
          await dispatch(
            call({
              url: `/audios/${id}`,
              method: "DELETE",
            })
          );
          dispatch(REMOVE_APP_BY_PARAM(["audios"], "id", id));
        } catch (e) {
          return notification.info({
            message: "Ошибка!",
            description: "Не удалось удалить аудиоролик",
          });
        }
      },
      onCancel() {},
    });
  };

  const [playing, setPlaying] = React.useState(null);

  React.useEffect(() => {
    return () => {
      if (audio.current) {
        audio.current.pause();
        audio.current.removeEventListener("ended", () => setPlaying(null));
      }
    };
  }, []);

  const onPlayback = (a) => () => {
    if (playing === a.id) {
      setPlaying(null);
      audio.current.pause();
    } else {
      audio.current = new Audio(defaults.baseURL + "audios/audio/" + a.id);
      audio.current.play();
      audio.current.addEventListener("ended", () => setPlaying(null));
      setPlaying(a.id);
    }
  };

  const options = {
    status: {
      render: (val) => {
        if (val === "waiting")
          return (
            <Tag key="w" icon={<WarningOutlined />} color="warning">
              На проверке
            </Tag>
          );
        else if (val === "approved")
          return (
            <Tag key="s" icon={<CheckOutlined />} color="success">
              Готов к использованию
            </Tag>
          );
        else
          return (
            <Tag key="s" icon={<CloseOutlined />} color="error">
              Отказано в использовании
            </Tag>
          );
      },
    },
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            {playing === item.id ? (
              <Button
                onClick={onPlayback(item)}
                size="small"
                type="action_pause"
              >
                <PauseCircleOutlined />
              </Button>
            ) : (
              <Button onClick={onPlayback(item)} size="small" type="action">
                <PlayCircleOutlined />
              </Button>
            )}
            <Button
              onClick={() => onEdit(item.id)}
              size="small"
              type="action_edit"
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => onDelete(item.id)}
              size="small"
              type="action_delete"
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  };

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.id}
      {...props}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default TableComp;
