import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import { Col, Row, notification } from "antd";
import { call } from "@/actions/axios";
import { CheckCircleOutlined } from "@ant-design/icons";
import Intervals from "./Intervals";

const { TextArea } = Input;
const { confirm } = Modal;

const Step = (props) => {
  const { history, match, cust_id } = props;
  const [loading, setLoading] = React.useState(false);
  const [exists, setExists] = React.useState(null);

  React.useEffect(() => {
    const { data, match } = props;
    const item = _.find(data, { id: match.params.id });
    if (item) {
      form.current.setFieldsValue(item);
      setExists(item);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  const isNew = match.params.id === "new";

  const checkIntervals = () => {
    try {
      const temp = {};
      const interval = exists.interval_order;
      const array = Object.keys(interval);
      if (array.length === 0) return temp;
      array.forEach((key) => {
        if (interval[key].hide) temp[key] = null;
        else {
          const { interval_begin, interval_end, interval_weekdays } =
            interval[key];
          temp[key] = {
            interval_begin,
            interval_end,
            interval_weekdays,
          };
        }
      });
      return temp;
    } catch (e) {
      return {};
    }
  };

  const onSubmit = async () => {
    let values = await form.current.validateFields();
    setLoading(true);
    values = _.pickBy(values, _.identity);
    const interval_order = checkIntervals();
    if (_.isEmpty(interval_order)) {
      return Modal.warning({
        title: "Некорректные данные",
        content: "Необходимо добавить интервалы",
      });
    }
    values.interval_order = interval_order;
    try {
      if (isNew) {
        await dispatch(
          call({
            url: "/api/customer/calendars/private/sets/" + cust_id,
            method: "POST",
            data: values,
          })
        );
      } else {
        await dispatch(
          call({
            url: `/api/customer/calendars/private/sets/${cust_id}/${match.params.id}`,
            method: "PATCH",
            data: values,
          })
        );
      }
      setLoading(false);

      confirm({
        title:
          "Календарный интервал успешно " + (isNew ? "добавлен" : "обновлен"),
        icon: <CheckCircleOutlined className="alert-icon" />,
        content: "Желаете вернуться к списку календарных интервалов?",
        okText: "Добавить еще",
        cancelText: "Вернуться",
        onOk() {
          form.current.resetFields();
        },
        onCancel() {
          history.push("/calendars");
        },
      });
    } catch (e) {
      setLoading(false);
      const message = isNew ? "добавить" : "обновить";
      notification.error({
        message: "Ошибка",
        description: `Не удалось ${message} календарный интервал`,
      });
    }
  };

  const setIntervals = (items) => {
    const interval_order = {};
    if (items.length > 0) {
      items.forEach((item, key) => {
        interval_order[key + 1] = { ...item };
      });
    }
    setExists({ ...exists, interval_order });
  };

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Наименование группы"
              name="calendar_interval_set_name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Описание" name="customer_comment">
              <TextArea disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Intervals
              data={exists && exists.interval_order}
              setIntervals={setIntervals}
            />
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.calendars,
  cust_id: state.auth.cust_id,
});

export default withRouter(connect(mapStateToProps)(Step));
