import React from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { GET_USER_SIP } from "@/actions/auth";

export default function useFetchingSip(cust_id, msisdn) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    try {
      dispatch(GET_USER_SIP(cust_id, msisdn));
    } catch (e) {
      // console.log(e.message);
      notification.error({
        message: "Ошибка!",
        description: "Не удалось получить пользователя SIP",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
