import _ from "lodash";
import {sortAlphaNumeric} from "../../../utils";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Id/Кампания",
      dataIndex: "campaignIdAndName",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Тип кaмпании",
      dataIndex: "campaignType",
      sorter: true
    },
    {
      title: "Дата и время начала кампании",
      dataIndex: "campaignFromDate",
      sorter: true,
    },
    {
      title: "Дата и время завершения кампании",
      dataIndex: "campaignEndDate",
      sorter: true,
    },
    {
      title: "Количество операторов",
      dataIndex: "activeOperatorsCount",
      sorter: true,
    },
    {
      title: "Всего номеров в кампании",
      dataIndex: "campaignContactsTotalCount",
      sorter: true,
    },
    {
      title: "Всего успешно обработанных номеров",
      dataIndex: "campaignContactsAnsweredCount",
      sorter: true,
    },
    {
      title: "% выполнения успешно обработанных номеров",
      dataIndex: "answeredToTotalCount",
      sorter: true,
    },
    {
      title: "Всего неуспешно обработанных номеров",
      dataIndex: "campaignContactsAnsweredIncompleteCount",
      sorter: true,
    },
    {
      title: "Количество попыток дозвона",
      dataIndex: "attempts",
      sorter: true,
    },
    {
      title: "Общее количество попыток дозвона",
      dataIndex: "attempts",
      sorter: true,
    },
    {
      title: "Всего обработанных номеров",
      dataIndex: "campaignContactsDialedCount",
      sorter: true,
    },
    {
      title: "% выполнения по обработанным номерам",
      dataIndex: "dialedToTotalCount",
      sorter: true,
    },
    {
      title: "Всего необработанных номеров",
      dataIndex: "campaignContactsNotDialedCount",
      sorter: true,
    },
    {
      title: "Дата и время завершения кампании",
      dataIndex: "campaignFactEndDate",
      sorter: true,
    },
    {
      title: "Запись разоговора",
      dataIndex: "record",
      sorter: true,
    },
    {
      title: "Описание",
      dataIndex: "campaignDescription",
      sorter: true,
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          return sortAlphaNumeric(a[dataIndex], b[dataIndex]);
        };
      }
    });
  }

  return data;
}
