import { call } from "./axios";
import {value} from "lodash/seq";
export const SET_APP = (path, value) => ({ type: "SET_APP", path, value });
export const SET_APP_BY_PARAM = (path, param, value) => ({
  type: "SET_APP_BY_PARAM",
  path,
  param,
  value,
});
export const SET_APPS = (arr) => ({ type: "SET_APPS", arr });
export const PUSH_APP = (path, value) => ({
  type: "PUSH_APP",
  path,
  value,
});
export const UNSHIFT_APP = (path, value) => ({
  type: "UNSHIFT_APP",
  path,
  value,
});
export const REMOVE_APP_BY_PARAM = (path, key, value) => ({
  type: "REMOVE_APP_BY_PARAM",
  path,
  key,
  value,
});

export const GET_CAMPAIGNS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["campaignsLoading"], true));
  const temp = [];
  try {
  const { data } = await dispatch(
    call({ url: "/api/customer/campaigns/" + cust_id })
  );
    const array = data.campaign_id;
    Object.keys(array).map((id) => temp.push({ ...array[id], id }));
  } catch (e) {
    dispatch(SET_APP(["campaignsLoading"], false));
  }

  dispatch(
    SET_APPS([
      { path: ["campaigns"], value: temp },
      { path: ["campaignsLoading"], value: false },
    ])
  );
};

export const GET_DELETED_CAMPAIGNS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["campaignsLoading"], true));
  const { data } = await dispatch(call({ url: "/campaigns/" + cust_id }));

  // console.log(data);
  dispatch(
    SET_APPS([
      { path: ["campaigns"], value: data },
      { path: ["campaignsLoading"], value: false },
    ])
  );
};

export const GET_CAMPAIGN_DETAILED =
  (campaign_id) => async (dispatch, getState) => {
    let { cust_id } = getState().auth;
    try {
      const { data } = await dispatch(
        call({ url: `/api/customer/campaigns/${cust_id}/${campaign_id}` })
      );
      // console.log(data);
      const { [campaign_id]: campaign } = data.campaign_id;
      return campaign;
    } catch (e) {
      // console.log(e.message);
    }
  };

export const GET_CALENDARS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["calendarsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/customer/calendars/private/sets/" + cust_id })
    );

    const array = data.calendar_interval_set_id;
    Object.keys(array).forEach((key) => temp.push({ ...array[key], id: key }));
  } catch (e) {
    return dispatch(SET_APP(["calendarsLoading"], false));
  }
  dispatch(
    SET_APPS([
      { path: ["calendars"], value: temp },
      { path: ["calendarsLoading"], value: false },
    ])
  );
};

export const GET_AONS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["aonsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/customer/aons/numbers/" + cust_id })
    );

    const array = data.msisdn;
    Object.keys(array).forEach((key) =>
      temp.push({ ...array[key], msisdn: key })
    );
  } catch (e) {
    return dispatch(SET_APP(["aonsLoading"], false));
  }
  dispatch(
    SET_APPS([
      { path: ["aons"], value: temp },
      { path: ["aonsLoading"], value: false },
    ])
  );
};

export const GET_OPERATORS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["operatorsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/customer/operators/msisdns/assigned/" + cust_id })
    );

    const array = data.msisdn;
    Object.keys(array).forEach((key) =>
      temp.push({ ...array[key], msisdn: key })
    );
  } catch (e) {
    return dispatch(SET_APP(["operatorsLoading"], false));
  }
  dispatch(
    SET_APPS([
      { path: ["operators"], value: temp },
      { path: ["operatorsLoading"], value: false },
    ])
  );
};

export const GET_GROUPS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["groupsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/customer/operators/groups/" + cust_id })
    );

    const array = data.operator_group_id;
    Object.keys(array).forEach((key) => temp.push({ ...array[key], id: key }));
  } catch (e) {
    return dispatch(SET_APP(["groupsLoading"], false));
  }
  dispatch(
    SET_APPS([
      { path: ["groups"], value: temp },
      { path: ["groupsLoading"], value: false },
    ])
  );
};

export const GET_AUDIOS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["audiosLoading"], true));

  try {
    const { data } = await dispatch(call({ url: "/audios/" + cust_id }));
    dispatch(
      SET_APPS([
        { path: ["audios"], value: data },
        { path: ["audiosLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["audiosLoading"], false));
  }
};

//GET UPLOADED CONTACTS FOR CAMPAIGN
export const GET_CONTACTS = (id) => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["contactsLoading"], true));
  try {
    const { data } = await dispatch(
      call({
        url: `/api/admin/campaigns/${cust_id}/${id}/contacts/basic`,
        method: "GET",
      })
    );
    dispatch(
      SET_APPS([
        { path: ["contacts"], value: data.contacts },
        { path: ["contactsLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["contactsLoading"], false));
  }
};

//GET ARM LIST
export const GET_ARM_LIST = (campaign_id) => async (dispatch, getState) => {
  let { cust_id, user } = getState().auth;
  const { msisdn } = user;
  dispatch(SET_APPS([{ path: ["armLoading"], value: true }]));
  try {
    const { data } = await dispatch(
      call({
        url: `/lists/${cust_id}/arm?msisdn=${msisdn}&campaign_id=${campaign_id}`,
      })
    );
    dispatch(
      SET_APPS([
        { path: ["armData"], value: data },
        { path: ["armLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["armLoading"], false));
  }
};

//GET UPLOADED TARGET CONTACT --LISTS--
export const GET_CONTACT_LISTS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APPS([{ path: ["listsLoading"], value: true }]));
  try {
    const { data } = await dispatch(
      call({
        url: `/lists/${cust_id}?short=1`,
        method: "GET",
      })
    );
    dispatch(
      SET_APPS([
        { path: ["lists"], value: data },
        { path: ["listsLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["listsLoading"], false));
  }
};

export const ASSIGN_CAMPAIGN_TO_LIST =
  (list_id, campaign_id) => async (dispatch, getState) => {
    let { cust_id } = getState().auth;
    return await dispatch(
      call({
        url: `/lists/${cust_id}?short=1`,
        method: "POST",
        data: {
          list_id,
          campaign_id,
        },
      })
    );
  };

export const UPLOAD_CONTACTS =
  (contacts, campaign_id) => async (dispatch, getState) => {
    let { cust_id } = getState().auth;
    return await dispatch(
      call({
        url: `/api/customer/campaigns/${cust_id}/${campaign_id}/contacts`,
        method: "POST",
        data: {
          contacts,
        },
      })
    );
  };

export const CREATE_CAMPAIGN_LIST =
  (campaign_id, contacts) => async (dispatch, getState) => {
    let { cust_id } = getState().auth;
    try {
      await dispatch(
        call({
          url: `/lists/${cust_id}/create`,
          method: "POST",
          data: { campaign_id, contacts },
        })
      );
    } catch (e) {}
  };

export const GET_STATUSES = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["statusesLoading"], true));
  try {
    const { data } = await dispatch(call({ url: "/statuses/" + cust_id }));
    dispatch(
      SET_APPS([
        { path: ["statuses"], value: data },
        { path: ["statusesLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["statusesLoading"], false));
  }
};

export const GET_SCENARIOS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["statusesLoading"], true));
  try {
    const { data } = await dispatch(call({ url: "/scenarios/" + cust_id }));
    dispatch(
      SET_APPS([
        { path: ["scenarios"], value: data },
        { path: ["scenariosLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["scenariosLoading"], false));
  }
};

export const GET_REPORT_OPERATORS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["reportLoading"], true));
  try {
    const { data } = await dispatch(call({ url: "api/customer/statistics/operators/longtime/" + cust_id }));
    dispatch(
      SET_APPS([
        {path: ["reportOperators"], value: data},
        {path: ["reportLoading"], value: false},
      ])
    )
  } catch (e) {
    return dispatch(
      SET_APPS([
        {path: ["reportOperators"], value: null},
        {path: ["reportLoading"], value: false},
      ])
    );
  }
}
export const GET_REPORT_CAMPAIGNS = () => async (dispatch, getState) => {
  let { cust_id } = getState().auth;
  dispatch(SET_APP(["reportLoading"], true));
  try {
    const { data } = await dispatch(call({ url: "api/customer/statistics/campaigns/" + cust_id }));
    dispatch(
      SET_APPS([
        {path: ["reportCampaigns"], value: data},
        {path: ["reportLoading"], value: false},
      ])
    )
  } catch (e) {
    return dispatch(
      SET_APPS([
        {path: ["reportCampaigns"], value: null},
        {path: ["reportLoading"], value: false},
      ])
    );
  }
}
