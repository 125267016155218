import React from "react";
import moment from "moment";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Radio, Form, Input, notification } from "antd";
import { Col, Row, DatePicker, Modal, Select } from "antd";
import { SET_APP } from "@/actions/app";
import { call } from "@/actions/axios";
import {
  GET_CALENDARS,
  GET_AUDIOS,
  GET_CAMPAIGNS,
  GET_STATUSES,
} from "@/actions/app";
import Picker from "./Picker";
import defaults from "@/defaults";
import { formatDataByCampaignType } from "./";

import { Context } from "../";
import { Spinner } from "@/ui/Loading";

const { TextArea } = Input;
const { Option } = Select;

const Step = (props) => {
  const cust_id = useSelector((state) => state.auth.cust_id);
  const customerInfo = useSelector((state) => state.auth.customerInfo);
  const { history, match } = props;
  const { calendars, calendarsLoading } = props;
  const { audios, audiosLoading } = props;
  const { statuses, statusesLoading } = props;
  const [loading, setLoading] = React.useState(false);
  const [picker, setPicker] = React.useState(null);

  const [selectedCalendars, setSelectedCalendars] = React.useState([]);
  const [selectedAbonentAudios, setSelectedAbonentAudios] = React.useState([]);
  const [selectedOperatorAudios, setSelectedOperatorAudios] = React.useState(
    []
  );
  const [dateChanged, setDateChanged] = React.useState(false);

  const context = React.useContext(Context);

  const { setStep, values, setValues } = context;

  const form_ref = React.useRef();
  const calendar_ref = React.useRef();
  const audio_a_ref = React.useRef();
  const audio_o_ref = React.useRef();
  const dispatch = useDispatch();

  const isNew = match.params.id === "new";

  const getStatus = async () => {
    try {
      const { data } = await dispatch(
        call({ url: `/statuses/${cust_id}/${match.params.id}` })
      );
      if (data) form_ref.current.setFieldsValue({ status_id: data.id });
    } catch (e) {}
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(GET_CALENDARS());
      await dispatch(GET_AUDIOS());
      await dispatch(GET_STATUSES());
      if (values) {
        // console.log(values);
        if (values.calendar_interval_set_id) {
          const c_id = values.calendar_interval_set_id.toString();
          const calendar = _.find(calendars, {
            id: c_id,
          });
          if (calendar) {
            form_ref.current.setFieldsValue({
              calendar: calendar.calendar_interval_set_name,
            });
            setSelectedCalendars([calendar]);
          }
        }
        if (values.scenario_args) {
          const [audio_a_id, audio_o_id] = values.scenario_args;
          const audio_a = _.find(audios, {
            announcement_file_id: audio_a_id,
          });
          const audio_o = _.find(audios, {
            announcement_file_id: audio_o_id,
          });
          const _values = {};
          if (audio_a) {
            _values.audio_a = audio_a.name;
            setSelectedAbonentAudios([audio_a]);
          }
          if (audio_o) {
            _values.audio_o = audio_o.name;
            setSelectedOperatorAudios([audio_o]);
          }

          form_ref.current.setFieldsValue(_values);
        }

        if (values.customer_comment) {
          form_ref.current.setFieldsValue({
            customer_comment: values.customer_comment,
          });
        }
        if (values.from_date) {
          const startAt = moment.utc(values.from_date);
          if (startAt.isValid()) {
            form_ref.current.setFieldsValue({
              startAt: startAt.local(),
            });
          }
        }
        if (values.to_date) {
          const endAt = moment.utc(values.to_date);
          if (endAt.isValid()) {
            form_ref.current.setFieldsValue({
              endAt: endAt.local(),
            });
          }
        }

        if (values.record_audio || values.record_audio === 0) {
          form_ref.current.setFieldsValue({
            recording: values.record_audio,
          });
        }

        if (values.selectedCalendars) {
          setSelectedCalendars(values.selectedCalendars);
        }
        if (values.selectedAbonentAudios) {
          setSelectedAbonentAudios(values.selectedAbonentAudios);
        }
        if (values.selectedOperatorAudios) {
          setSelectedOperatorAudios(values.selectedOperatorAudios);
        }

        if (values.status) {
          form_ref.current.setFieldsValue({
            status_id: values.status_id,
          });
        } else getStatus();
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getValues = async () => {
    const newValues = await form_ref.current.validateFields();

    const temp = { ...values, ...newValues };
    temp.selectedCalendars = selectedCalendars;
    temp.selectedAbonentAudios = selectedAbonentAudios;
    temp.selectedOperatorAudios = selectedOperatorAudios;
    // console.log(temp);
    const data = formatDataByCampaignType(temp);
    // console.log(data);
    return data;
  };

  const onChange = (index, text) => {
    form_ref.current.setFieldsValue({ [index]: text });
  };

  const onSubmit = async () => {
    const { match } = props;
    try {
      const data = await getValues();
      // console.log({values, data});

      let campaign_id;

      setLoading(true);
      if (isNew) {
        const campaign = await dispatch(
          call({
            url: `/api/customer/campaigns/${cust_id}`,
            method: "POST",
            data,
          })
        );
        campaign_id = campaign.data.campaign_id;
      } else {
        const {campaign_status} = props.campaigns.find(c => c.id === match.params.id);
        let dataSet = {};
        if (campaign_status === 'NOT_STARTED') {
          dataSet = {...data, scenario_id: undefined};
          // if(dateChanged){
            const isValid = moment(data.from_date) > moment().add(3, "minutes");
            if(!isValid){
              dataSet = {...data, from_date: moment().add(3, "minutes").utc().format("YYYY-MM-DD HH:mm")}
            }
          // }
        } else if(campaign_status === 'ON_HOLD'){
          const {operator_interval_between_calls, calendar_interval_set_id, queue_priority_number} = data;
          dataSet = {operator_interval_between_calls, calendar_interval_set_id, queue_priority_number};
        } else {
          const { admin_comment, customer_comment} = data;
          dataSet = {admin_comment, customer_comment}
        }
        // console.log({campaign_status, data, dataSet});
        await dispatch(
          call({
            url: `/api/customer/campaigns/${cust_id}/${match.params.id}`,
            method: "PATCH",
            data: dataSet,
          })
        );
        campaign_id = match.params.id;
      }

      if (data.status_id) {
        await dispatch(
          call({
            url: `/statuses/${cust_id}/assign`,
            method: "POST",
            data: {
              template_id: data.status_id,
              campaign_id,
            },
          })
        );
      }
      if (data.scenario) {
        await dispatch(
          call({
            url: `/scenarios/${cust_id}/assign`,
            method: "POST",
            data: {
              scenario_id: data.scenario,
              campaign_id,
            },
          })
        );
      }
      await dispatch(GET_CAMPAIGNS());
      dispatch(SET_APP(["step"], 1));
      dispatch(SET_APP(["campaign"], null));
    } catch (e) {
      // console.log(e.message);
      setLoading(false);
      return notification.error({
        message: "Ошибка!",
        description: "Не удалось создать кампанию",
      });
    }

    setLoading(false);

    Modal.success({
      title: "Кампания успешно " + (isNew ? "добавлена" : "обновлена"),
      okText: "Вернуться",
      onOk() {
        history.push("/campaigns");
      },
    });
  };

  const openPicker = (picker) => () => {
    setPicker(picker);
    if (picker === "calendar") calendar_ref.current.blur();
    else if (picker === "audio_a") audio_a_ref.current.blur();
    else if (picker === "audio_o") audio_o_ref.current.blur();
  };

  let pickerProps = {
    columns: [
      {
        title: "Название аудиоролика",
        dataIndex: "name",
      },
      { title: "Описание", dataIndex: "description" },
    ],
    data: audios,
    multiple: false,
    indexKey: "id",
    displayIndex: "name",
    setPicker,
  };
  if (picker === "audio_a") {
    pickerProps = {
      ...pickerProps,
      selected: selectedAbonentAudios,
      setSelected: setSelectedAbonentAudios,
    };
  } else if (picker === "audio_o") {
    pickerProps = {
      ...pickerProps,
      selected: selectedOperatorAudios,
      setSelected: setSelectedOperatorAudios,
    };
  } else if (picker === "calendar") {
    pickerProps = {
      ...pickerProps,
      columns: [
        {
          title: "Наименование календаря",
          dataIndex: "calendar_interval_set_name",
        },
        { title: "Описание", dataIndex: "customer_comment" },
      ],
      data: calendars,
      indexKey: "id",
      displayIndex: "calendar_interval_set_name",
      selected: selectedCalendars,
      setSelected: setSelectedCalendars,
    };
  }

  const cacheValues = async () => {
    let values = await form_ref.current.getFieldsValue();
    values.selectedCalendars = selectedCalendars;
    values.selectedAbonentAudios = selectedAbonentAudios;
    values.selectedOperatorAudios = selectedOperatorAudios;
    return values;
  };

  const onStatus = async () => {
    const newValues = await cacheValues();
    dispatch(SET_APP(["step"], 2));
    dispatch(SET_APP(["campaign"], { ...values, ...newValues }));
    let path = "/campaigns/";
    if (isNew) path += "new";
    else path += match.params.id;
    localStorage.setItem("backUrl", path);
    history.push("/statuses");
  };

  const onBack = async () => {
    const newValues = await cacheValues();
    setValues({ ...values, ...newValues });
    setStep(1);
  };

  const type_id = values.type.value.toString();

  const handleDateStart = (date, dateString) => {
    setDateChanged(true);
    if(!isNew && date < moment().add(3, "minutes")){
      // console.log('WRONG DATE!!!!!!')
      Modal.warn(
        {
          title: "Внимание! Выбранная дата и время не валидны.",
          content: (<div>
            <p>При сохранении изменений автоматически будет установлено текущее время + 3 минуты, либо выберите другую дату и время.</p>
          </div>),
          okText: "Продолжить"
        }
      )
    }
  }

  return (
    <>
      <Picker onChange={onChange} picker={picker} {...pickerProps} />
      <Form layout="vertical" ref={form_ref} initialValues={values}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Календарные интервалы"
              name="calendar"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                ref={calendar_ref}
                disabled={loading || calendarsLoading}
                onFocus={openPicker("calendar")}
                readOnly={true}
                placeholder="Выберите календарный интервал"
                suffix={calendarsLoading ? <Spinner /> : null}
              />
            </Form.Item>
            <Form.Item
              label="Звуковое приветствие для абонента"
              name="audio_a"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                ref={audio_a_ref}
                disabled={loading || audiosLoading}
                onFocus={openPicker("audio_a")}
                readOnly={true}
                placeholder="Выберите звуковое приветствие"
                suffix={audiosLoading ? <Spinner /> : null}
              />
            </Form.Item>
            {type_id === defaults.types.AUTOINFORMATOR ||
              type_id === defaults.types.AUTOINFORMATOR_DTMF || (
                <Form.Item
                  label="Звуковое приветствие для оператора"
                  name="audio_o"
                  rules={[
                    { required: true, message: "Данное поле обязательно" },
                  ]}
                >
                  <Input
                    ref={audio_o_ref}
                    disabled={loading || audiosLoading}
                    onFocus={openPicker("audio_o")}
                    readOnly={true}
                    placeholder="Выберите звуковое приветствие"
                    suffix={audiosLoading ? <Spinner /> : null}
                  />
                </Form.Item>
              )}
            {type_id === defaults.types.AUTOINFORMATOR ||
              type_id === defaults.types.AUTOINFORMATOR_DTMF || (
                <Form.Item
                  label="Список статусов"
                  name="status_id"
                  help={<Statuses onStatus={onStatus} />}
                >
                  <Select
                    disabled={loading || statusesLoading}
                    placeholder="Выберите список"
                    allowClear={true}
                  >
                    {statuses
                      ? statuses.map((status) => (
                          <Option key={status.id} value={status.id}>
                            {status.title}
                          </Option>
                        ))
                      : []}
                  </Select>
                </Form.Item>
              )}
          </Col>
          <Col span={12}>
            <Form.Item label="Описание" name="customer_comment">
              <TextArea
                min={0}
                disabled={loading}
                placeholder="Введите текст"
              />
            </Form.Item>
            <Form.Item
              label="Период действия обзвона от"
              name="startAt"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <DatePicker
                showNow={false}
                showTime={{ format: "HH:mm", minuteStep: 5 }}
                placeholder="Выберите дату и время"
                format="DD.MM.YYYY HH:mm"
                disabled={loading}
                onChange={handleDateStart}
              />
            </Form.Item>
            <Form.Item
              label="Период действия обзвона по"
              name="endAt"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <DatePicker
                showNow={false}
                showTime={{ format: "HH:mm", minuteStep: 5 }}
                placeholder="Выберите дату и время"
                format="DD.MM.YYYY HH:mm"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item label="Запись разговора" name="recording">
              <Radio.Group
                disabled={loading || customerInfo.record_audio_allowed === 0}
              >
                <Radio value={1}>Включить</Radio>
                <Radio value={0}>Выключить</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button
          disabled={loading}
          onClick={onSubmit}
          type="success"
          style={{ marginLeft: 14 }}
        >
          {(isNew ? "Создать" : "Обновить") + " кампанию"}
        </Button>
        <Button disabled={loading} onClick={onBack} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const Statuses = ({ onStatus }) => {
  return (
    <div>
      <Button className="info action" type="link" onClick={onStatus}>
        + добавить новый
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  const audios = _.filter(state.app.audios, (o) => !!o.announcement_file_id);
  return {
    calendars: state.app.calendars,
    calendarsLoading: state.app.calendarsLoading,
    audios,
    audiosLoading: state.app.audiosLoading,
    statuses: state.app.statuses,
    statusesLoading: state.app.statusesLoading,
    campaigns: state.app.campaigns
  };
};

export default withRouter(connect(mapStateToProps)(Step));
