import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button, Result, Radio, Space } from "antd";
import { Checkbox, Row, Col } from "antd";
import { message } from "antd";
import { call } from "@/actions/axios";
import "./index.less";

const Preview = (props) => {
  const { scenario, abonent } = props;

  const [loading, setLoading] = React.useState(false);

  const [started, setStarted] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [cache, setCache] = React.useState([]);
  // const [first, setFirst] = React.useState(null);

  const [answers, setAnswers] = React.useState({});

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const classNames = ["scenario__preview"];

  // const onBack = () => {
  //   if (first && current && first.id === current.id) {
  //     setCurrent(null);
  //     return setStarted(false);
  //   } else if (finished) {
  //     setFinished(false);
  //   }

  //   const _cache = [...cache];
  //   const previous = _cache.pop();

  //   setCurrent(previous);
  //   setCache(_cache);
  // };
  const onNext = async () => {
    if (!current) {
      setCurrent(scenario.questions[0]);
      // setFirst(scenario.questions[0]);
      return setStarted(true);
    }

    // console.log(answers[current.id]);

    if (
      !answers[current.id] ||
      (_.isArray(answers[current.id].value) &&
        answers[current.id].value.length === 0)
    ) {
      return message.info("Выберите один из вариантов");
    }

    const id = answers[current.id].next;

    setCache([...cache, current]);

    if (id) {
      const next = _.find(scenario.questions, { id });
      if (next) {
        await saveAnswer();
        return setCurrent(next);
      }
    }
    await saveAnswer();
    setFinished(true);
  };

  const saveAnswer = async () => {
    if (!abonent) return;
    const { msisdn } = user;
    const { value } = answers[current.id];
    const option_ids = _.isArray(value) ? value : [value];
    setLoading(true);
    try {
      await dispatch(
        call({
          url: `/scenarios/${current.id}/answer`,
          method: "POST",
          data: {
            abonent_id: 1,
            msisdn,
            option_ids,
            scenario_id: scenario.id,
          },
        })
      );
    } catch (e) {
      message.error("Не удалось сохранить ответ");
    }
    setLoading(false);
  };

  const onChange = (type) => (e) => {
    let _answers = { ...answers };
    if (type === "radio") {
      _answers[current.id] = {
        value: e.target.value,
        next: e.target.next,
      };
    } else if (type === "check") {
      _answers[current.id] = { value: e };
      if (e.length > 0) {
        const [id] = e;
        const option = _.find(current.options, { id });
        _answers[current.id] = { ..._answers[current.id], next: option.next };
      }
    }
    // console.log(_answers);
    setAnswers(_answers);
  };

  let buttons = [
    // <Button onClick={onBack} key="back">
    //   Назад
    // </Button>,
    <Button loading={loading} onClick={onNext} type="primary" key="next">
      Далее
    </Button>,
  ];

  let title = "",
    subTitle = "";

  if (!started) {
    title = scenario.name;
    subTitle = scenario.description;
    buttons = [
      <Button onClick={onNext} type="primary" key="next">
        Начать
      </Button>,
    ];
  } else if (finished) {
    title = scenario.finishText;
    buttons = null;
    // buttons = [
    //   <Button onClick={onBack} type="primary" key="next">
    //     Вернуться на шаг назад
    //   </Button>,
    // ];
  } else {
    classNames.push("question");
    title = current.question;
    if (current.type === "radio") {
      subTitle = (
        <Radio.Group
          onChange={onChange("radio")}
          value={answers[current.id] && answers[current.id].value}
        >
          <Space direction="vertical">
            {current.options.map((item) => (
              <Radio key={item.id} value={item.id} next={item.next}>
                {item.option}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (current.type === "check") {
      subTitle = (
        <Checkbox.Group
          value={answers[current.id] && answers[current.id].value}
          style={{ width: "100%" }}
          onChange={onChange("check")}
        >
          <Row>
            {current.options.map((item) => (
              <Col span={24} key={item.id}>
                <Checkbox next={item.next} value={item.id}>
                  {item.option}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      );
    }
  }

  return (
    <>
      <Result
        className={classNames.join(" ")}
        title={title}
        subTitle={subTitle}
        extra={buttons}
      />
    </>
  );
};

export default Preview;
