import React from "react";
import { useDispatch } from "react-redux";
import { Modal, notification } from "antd";
import List from "./List";
import SelectList from "./SelectList";
import defaults from "@/defaults";
import { ASSIGN_CAMPAIGN_TO_LIST, UPLOAD_CONTACTS } from "@/actions/app";
import { CREATE_CAMPAIGN_LIST, GET_CAMPAIGNS } from "@/actions/app";

const Contacts = (props) => {
  const [loading, setLoading] = React.useState(false);
  const onAdd = async () => {
    if (contacts.length === 0) {
      return notification.warn({
        message: "Пустой список",
        description: "Добавьте контакты для запуска кампании на обзвон",
      });
    }
    // console.log(contacts);
    setLoading(true);
    const type_id = campaign.scenario_id.toString();
    try {
      if (type_id === defaults.types.TARGETCONTACT) {
        await dispatch(ASSIGN_CAMPAIGN_TO_LIST(list, campaign.id));
      } else if (type_id === defaults.types.OPERATORS) {
        await dispatch(CREATE_CAMPAIGN_LIST(campaign.id, contacts));
      }
    } catch (e) {
      setLoading(false);
      return notification.error({
        message: "Ошибка",
        description: "Не удалось привязать контакты к кампании",
      });
    }
    try {
      await dispatch(UPLOAD_CONTACTS(contactsFormatter(contacts), campaign.id));
    } catch (e) {
      setLoading(false);
      return notification.error({
        message: "Ошибка",
        description: "Не удалось выгрузить контакты в SIP",
      });
    }
    setLoading(false);
    await dispatch(GET_CAMPAIGNS());
    setCampaign(null);
    setContacts([]);
    notification.success({
      message: "Контакты на обзвон",
      description: "Список контактов на обзвон успешно добавлен",
    });
  };
  const { campaign, setCampaign } = props;
  const [contacts, setContacts] = React.useState([]);
  const [list, setList] = React.useState(null);

  const dispatch = useDispatch();

  const renderContent = () => {
    if (!campaign) return null;
    else if (campaign.scenario_id.toString() === defaults.types.TARGETCONTACT)
      return (
        <SelectList
          loading={loading}
          setContacts={setContacts}
          setList={setList}
        />
      );
    else
      return (
        <List loading={loading} setContacts={setContacts} contacts={contacts} />
      );
  };

  const onCancel = () => {
    if (loading) return;
    setCampaign(null);
    setContacts([]);
  };

  return (
    <Modal
      title="Добавить контакты на обзвон"
      visible={!!campaign}
      onOk={onAdd}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      forceRender={true}
      onCancel={onCancel}
    >
      {renderContent()}
    </Modal>
  );
};

const contactsFormatter = (contacts) => {
  return contacts.map(({ msisdn, external_id }) => {
    return {
      id: external_id,
      msisdn: msisdn.replace(/[^\d]/g, ""),
    };
  });
};

export default Contacts;
