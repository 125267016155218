import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { PageHeader, Input, Button, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table from "./Table";
import { SET_APP, GET_GROUPS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading } = props;

  const onCreate = () => {
    history.push("/groups/new");
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_APP(["group"], null));
    dispatch(GET_GROUPS());
  }, [dispatch]);

  const backUrl = localStorage.getItem("backUrl");

  return (
    <>
      <PageHeader
        title="Список групп операторов"
        subTitle={<SearchInput />}
        onBack={() => history.push(backUrl ? backUrl : "/operators")}
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Создать группу
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const SearchInput = () => (
  <Input
    placeholder="Поиск"
    suffix={<SearchOutlined className="search-icon" />}
  />
);

const mapStateToProps = (state) => ({
  data: state.app.groups,
  loading: state.app.groupsLoading,
});

export default connect(mapStateToProps)(Screen);
