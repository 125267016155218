import Keycloak from "keycloak-js";
import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

export default new Keycloak({
  realm: "B2B",
  url: "https://pre-accounts.kcell.kz/auth",
  clientId: "autodialer-backend",
});

function PrivateRouteHOC({ component: Component, ...rest }) {
  const { keycloak } = useKeycloak();

  const render = (props) => {
    // return <Component {...props} />;
    const { user, role, roles } = rest;
    if (user && !role) {
      return (
        <Redirect
          to={{
            pathname: "/access",
            state: { from: props.location },
          }}
        />
      );
    } else if (keycloak.authenticated) {
      if (roles.indexOf(role.toUpperCase()) === -1) {
        return (
          <Redirect
            to={{
              pathname: "/404",
              state: { from: props.location },
            }}
          />
        );
      }
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={render} />;
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  role: state.auth.role,
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteHOC);
