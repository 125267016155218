import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useDispatch, connect, useSelector } from "react-redux";
import { Button, Form, Input, Modal, Upload } from "antd";
import { Col, Row, Select, notification, Radio } from "antd";
// import { AudioPlayer } from "@/ui";
import {
  CheckCircleOutlined,
  PlayCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { call } from "@/actions/axios";
import { Loading } from "@/ui";
import Socket from "@/ui/Socket";
import { REMOVE_APP_BY_PARAM } from "@/actions/app";

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;
const { Dragger } = Upload;

const Step = (props) => {
  const { history, match } = props;
  const { languages, cust_id, customerInfo } = props;
  const [type, setType] = React.useState("upload");
  const [loading, setLoading] = React.useState(false);
  const isNew = match.params.id === "new";
  const [ready, setReady] = React.useState(!isNew);
  const [uploaded, setUploaded] = React.useState(false);

  const socket = React.useRef();

  React.useEffect(() => {
    socket.current = Socket("audios");
    return () => {
      if (socket.current) socket.current.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const { data, match } = props;
    const item = _.find(data, { id: parseInt(match.params.id) });

    if (form.current) {
      if (item) {
        form.current.setFieldsValue(item);
      } else form.current.setFieldsValue({ language: { value: "ru" } });
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const { match } = props;
    let values = await form.current.validateFields();
    if (type === "convert") {
      values.language = _.find(languages, { value: values.language.value });
    }
    values = _.pickBy(values, _.identity);
    setLoading(true);
    try {
      await dispatch(
        call({
          url: `/audios/${match.params.id}`,
          method: "PATCH",
          data: values,
        })
      );
      setLoading(false);
      socket.current.emit("audio-added");

      confirm({
        title: "Аудиоролик успешно загружен",
        icon: <CheckCircleOutlined className="alert-icon" />,
        content: "Желаете вернуться к списку аудиороликов?",
        okText: "Добавить еще",
        cancelText: "Вернуться",
        onOk() {
          form.current.resetFields();
          setReady(false);
        },
        onCancel() {
          history.push("/audios");
        },
      });
    } catch (e) {
      setLoading(false);
      return notification.error({
        message: "Ошибка",
        description: "Не удалось сохранить данные",
      });
    }
  };

  const onAudio = () => {
    const values = form.current.getFieldsValue();
    if (!values.text) {
      return notification.info({
        message: "Преобразование невозможно",
        description: "Введите текст для преобразования в голос",
      });
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setReady(true);
    }, 1000);
  };

  const convert = () => (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Язык" name={["language", "value"]}>
              <Select disabled={loading}>
                {languages.map((language) => (
                  <Option key={language.value} value={language.value}>
                    {language.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Текст для преобразования в голос" name="text">
              <TextArea disabled={loading} placeholder="Введите текст здесь" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button onClick={onAudio} type="action" loading={loading}>
        <PlayCircleOutlined />
        <span>Преобразовать текст в голос</span>
      </Button>
    </>
  );

  const token = useSelector((state) => state.auth.token);

  const customRequest = async (options) => {
    const { file } = options;
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-type": "multipart/form-data",
    };
    try {
      const { data } = await dispatch(
        call({
          url: `/audios/${cust_id}`,
          method: "POST",
          data: formData,
          headers,
        })
      );
      setUploaded(true);
      setReady(true);
      form.current.setFieldsValue({ name: file.name });
      history.push("/audios/" + data.id);
    } catch (e) {
      notification.error({
        message: "Ошибка",
        description: "Не удалось загрузить аудиоролик",
      });
    }
  };

  const upload = () => (
    <>
      <Dragger
        multiple={false}
        showUploadList={false}
        customRequest={customRequest}
      >
        <p className="ant-upload-drag-icon">
          <PaperClipOutlined />
        </p>
        <p className="ant-upload-text">
          Перетяните сюда файл, чтобы загрузить (в формате mp3)
        </p>
        <p className="ant-upload-hint">
          Или нажмите сюда, чтобы открыть файловый менеджер
        </p>
      </Dragger>
    </>
  );

  const options = [
    { label: "Загрузить готовый аудиоролик", value: "upload", disabled: ready },
    {
      label: "Преобразовать текст в голос",
      value: "convert",
      disabled: true || customerInfo.tts_allowed !== -1,
    },
  ];

  const onChange = (e) => {
    if (e.target.value !== type) {
      setReady(false);
      setType(e.target.value);
    }
  };

  const onCancel = async () => {
    if (!uploaded) history.goBack();
    else {
      const { match } = props;
      try {
        await dispatch(
          call({
            url: `/audios/${match.params.id}`,
            method: "DELETE",
          })
        );
        dispatch(REMOVE_APP_BY_PARAM(["audios"], "id", match.params.id));
        history.push("/audios");
      } catch (e) {
        return notification.info({
          message: "Ошибка!",
          description: "Не удалось удалить аудиоролик",
        });
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Radio.Group
        options={options}
        onChange={onChange}
        value={type}
        optionType="button"
        buttonStyle="solid"
      />
      <div className="tab-content">
        {type === "upload" && !ready && upload()}
        {type === "convert" && convert()}
      </div>
      {ready && <Audio form={form} loading={loading} />}
      <div className="buttons">
        <Button
          onClick={onSubmit}
          disabled={!ready}
          type="success"
          style={{ marginLeft: 14 }}
        >
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={onCancel} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const Audio = (props) => {
  const { form, loading } = props;

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Название аудиоролика"
              name="name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
            <Form.Item label="Описание" name="description">
              <TextArea disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* <AudioPlayer /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  cust_id: state.auth.cust_id,
  customerInfo: state.auth.customerInfo,
  data: state.app.audios,
  languages: state.app.languages,
});

export default withRouter(connect(mapStateToProps)(Step));
