import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {PageHeader, Input, Button, Card, Radio, DatePicker, Space} from "antd";
import {SET_APP, GET_CAMPAIGNS, GET_REPORT_OPERATORS, GET_REPORT_CAMPAIGNS} from "@/actions/app";
import TableOperators from "./Table/operators";
import TableCampaigns from "./Table/campaigns";
import TableCalls from "./Table/calls";
import TableAuto from "./Table/auto";
import "./index.less";
import {statuses} from "../Campaigns/Table/data";
import _ from "lodash";
import {columns as oColumns} from "./Table/operatorsData";
import {columns as aColumns} from "./Table/autoData";
import {columns as cColumns} from "./Table/campaignsData";
import {columns as caColumns} from "./Table/callsData";
import {exportToExcel} from "../../utils/excel";
import icon from "../../assets/images/excel.png";
import {call} from "../../actions/axios";
import defaults from "../../defaults";
import {intToHHMMSS} from "../../utils";

let timer;

const ScreenStats = (props) => {
  const {campaigns, cust_id} = props;
  const types = useSelector((state) => state.app.types);
  const [selected, setSelected] = useState("campaigns");
  const [cStat, setCStat] = useState([]);
  const [oStat, setOStat] = useState([]);
  const [caStat, setCaStat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startRange, setStartRange] = useState({from: 0, to: Date.now()})
  const [endRange, setEndRange] = useState({from: 0, to: Date.now()})
  // const [filter, setFilter] = useState([]);
  const dispatch = useDispatch();
  const activeCampaigns = campaigns.filter(c => {
    const st = c.campaign_status
    if (!(st === 'FINISHED' || st === 'ENDED' || st === 'DELETED')) {
      return true
    }
  })
  const filter = activeCampaigns.map(c => c.id);
  // console.log({filter})
  const getCampaignType = (id) => {
    const target = campaigns.find(c => c.id === id);
    const type = target.scenario_id;
    // console.log({id, target, type, types})
    return _.find(types, {value: parseInt(type)}).text;
  }

  const caItems = (rows) => {
    let data = [];
    if(rows && rows.length > 0){
      data = [...rows];
    }
    data = data.sort((a, b) => {
      return new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
    })
    const rdata = [];
    for (let c of data) {
      const entry = {
        campaignIdAndName: `${c.campaign_id} - ${c.campaign_name}`,
        campaignType: _.find(types, {value: parseInt(c.scenario_id)}).text,
        callAttempts: c.call_attempts,
        msisdn: c.msisdn,
        callDuration: (c.endAt && c.startedAt) ? (new Date(c.endAt).getTime() / 1000 - new Date(c.startedAt).getTime() / 1000).toFixed(0) + " сек." : null,
        dialingStatus: c.dialing_status === "NOT_DIALED" ? "Не набран" : defaults.statuses(c.dialing_status).status,
        startedAt: new Date(c.startedAt).toLocaleString(),
        endAt: new Date(c.endAt).toLocaleString(),
        dialed: c.dialed,
        aon: c.aonNumbers.join(", "),
        operator_msisdn: c.operator_msisdn,
        callRecord: '-'
      }
      rdata.push(entry)
    }
    return rdata;
  }

  const cItems = (rows) => {
    let data = [];
    if (rows && rows.length > 0) {
      for (let s of rows) {
        data.push({...s.data, id: s.campaign_id, ended: s.createdAt})
      }
    }
    data = data.sort((a, b) => {
      return new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
    })
    const rdata = [];
    for (let c of data) {
      // const st = c.campaign_status;
      // if (st === 'FINISHED' || st === 'ENDED' || st === 'DELETED') {
      //   continue;
      // }
      if(selected === "auto" && c.scenario_id !== 1){
        continue;
      }
      const entry = {
        campaignIdAndName: `${c.id} - ${c.campaign_name}`,
        campaignFromDate: new Date(c.from_date).toLocaleString(),
        campaignEndDate: new Date(c.to_date).toLocaleString(),
        campaignFactEndDate: new Date(c.ended).toLocaleString(),
        campaignType: _.find(types, {value: parseInt(c.scenario_id)}).text,
        // campaignType: getCampaignType(c.id),
        // campaignState: statuses[c.campaign_status].text,
        activeOperatorsCount: c.operators_online,
        campaignContactsTotalCount: c.contacts_count,
        campaignContactsAnsweredCount: c.successfully_completed,
        campaignContactsAnsweredIncompleteCount: c.unsuccessfully_completed,
        campaignContactsDialedCount: c.dialed,
        campaignContactsNotDialedCount: c.unprocessed_contacts,
        answeredToTotalCount: (c.contacts_count > 0 ? c.answered / c.contacts_count * 100 : 0).toFixed(2) + "%",
        answeredIncompleteToTotalCount: (c.contacts_count > 0 ? c.unsuccessfully_completed / c.contacts_count * 100 : 0).toFixed(2) + "%",
        dialedToTotalCount: (c.contacts_count > 0 ? c.dialed / c.contacts_count * 100 : 0).toFixed(2) + "%",
        attempts: c.call_attempts,
        record: c.record_audio > 0 ? 'Включено' : "Выключено",
        campaignDescription: c.customer_comment
      }
      rdata.push(entry)
    }
    return rdata;
  }


  const oItems = (rows) => {
    let data = [];
    if (rows && rows.length > 0) {
      for (let s of rows) {
        const {from_date, to_date, msisdn} = s;
        data.push({...s.data, id: s.campaign_id, from_date, to_date, msisdn})
      }
    }
    data = data.sort((a, b) => {
      return new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
    })
    const rdata = [];
    for (let c of data) {
      const entry = {
        campaignIdAndName: `${c.id} - ${c.campaign_name}`,
        operatorNameAndGroup: `${c.operator_represented_name} - ${c.operator_group_name}`,
        operatorLogin: c.msisdn,
        campaignFromDate: new Date(c.from_date).toLocaleString(),
        campaignToDate: new Date(c.to_date).toLocaleString(),
        campaignContactsTotalCount: c.contacts_count,
        campaignContactsAnsweredCount: c.successfully_completed_by_operator,
        answeredToTotalRate: c.successful_from_total_percent_by_operator,
        campaignContactsDialedCount: c.total_call_attempts_by_operator,
        answeredToDialedCount: c.successfully_completed_percent_by_operator,
        operatorPresenceTotalTime: "в разработке",
        conversationTotalTime: intToHHMMSS(c.total_conversation_time_sec),
        dialingTotalTime: intToHHMMSS(c.total_call_waiting_time_sec),
        conversationAverageTimePerCall: intToHHMMSS(c.medium_conversation_time_sec),
        // dialingAverageTimePerCall: c.medium_call_waiting_time_sec
      }
      rdata.push(entry);
    }
    return rdata;
  }

  useEffect(() => {
    dispatch(GET_CAMPAIGNS());
    // dispatch(GET_REPORT_CAMPAIGNS());
    // dispatch(GET_REPORT_OPERATORS());
    // timer = setTimeout(() => refresh(), 60 * 1000);
    // return () => {
    //   clearTimeout(timer);
    // }
  }, [])

  const onChange = (e) => {
    const {value} = e.target;
    if (value === "operators") {
      // console.log('GET REPS OPERS')
      // dispatch(GET_CAMPAIGNS());
    } else {
      // console.log('GET REPS CAMPS')
      // dispatch(GET_DELETED_CAMPAIGNS());
    }
    setSelected(value);
  };

  const exportData = () => {
    let columns, rows, title;
    switch (selected){
      case "campaigns":
        columns = cColumns(); rows = cItems(cStat); title =  "Отчет - Кампании"; break;
      case "auto":
        columns = aColumns(); rows = cItems(cStat); title =  "Отчет - Автоинформатор"; break;
      case "operators":
        columns = oColumns(); rows = oItems(oStat); title =  "Отчет - Операторы"; break;
      case "calls":
        columns = caColumns(); rows = caItems(caStat); title = "Отчет - Звонки"; break;
    }
    // const columns = cColumns();
    // const rows = cItems(cStat);
    // const title = 'Отчет - Кампании'
    exportToExcel(rows, {
      columns,
      fileName: title,
      sheetName: new Date().toLocaleDateString("ru-RU"),
      title
    })
  }

  const getStat = async () => {
    setLoading(true);
    let urlPart;
    switch (selected) {
      case "campaigns": urlPart = "campaigns"; break;
      case "auto": urlPart = "campaigns"; break;
      case "operators": urlPart = "operators"; break;
      case "calls": urlPart = "calls"; break;
      default: urlPart = "campaigns"; break;
    }
    const {data} = await dispatch(call({
      url: `/stat/${urlPart}/${cust_id}`,
      method: "POST",
      data: {
        startRange,
        endRange
      }
    }));
    // console.log(data);
    setLoading(false);
    if(selected === 'campaigns' || selected === "auto"){
      setCStat(data.stats)
    } else if(selected === "operators"){
      setOStat(data.stats);
    } else if(selected === "calls"){
      setCaStat(data.calls)
    }
  }

  const onStartRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dates[0].format('x'), ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setStartRange({
        from: dates[0].format('X')*1000,
        to: dates[1].format('X')*1000 || Date.now()
      })
    } else {
      // console.log('Clear');
    }
  };
  const onEndRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dates[0].format('x'), ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setEndRange({
        from: dates[0].format('X')*1000,
        to: dates[1].format('X')*1000 || Date.now()
      })
    } else {
      // console.log('Clear');
    }
  };

  return (
    <>
      <PageHeader
        title="Отчеты"
        extra={[
          <Button key="create" type="success" onClick={getStat}>
            Сформировать
          </Button>,
          <Button key="export" onClick={exportData}
                  style={{
                    backgroundColor: '#18e345',
                    borderColor: '#18e345'
                  }}
                  icon={<img className="excel-icon" src={icon} alt=""/>}
          >
            Excel
          </Button>
        ]}
      />
      <Card
        bordered={false}
        title={
          <Space direction="vertical" style={{width: "100%"}}>
            <Radio.Group value={selected} onChange={onChange}>
              <Radio.Button value="campaigns">Кампании</Radio.Button>
              <Radio.Button value="operators">Операторы</Radio.Button>
              <Radio.Button value="auto">Автоинформатор</Radio.Button>
              <Radio.Button value="calls">Звонки</Radio.Button>
            </Radio.Group>
            <Space direction={"horizontal"}>
              <span>Дата и время начала кaмпании</span>
              <DatePicker.RangePicker
                // showNow={false}
                // style={{width: 660}}
                showTime={{format: "HH:mm", minuteStep: 5}}
                placeholder={["от", "до"]}
                format="DD.MM.YYYY HH:mm"
                disabled={loading}
                onChange={onStartRangeChange}
              />
              <span>Дата и время завершения кaмпании</span>
              <DatePicker.RangePicker
                // showNow={false}
                // style={{width: 660}}
                showTime={{format: "HH:mm", minuteStep: 5}}
                placeholder={["от", "до"]}
                format="DD.MM.YYYY HH:mm"
                disabled={loading}
                onChange={onEndRangeChange}
              />
            </Space>
          </Space>
        }
      >
        {selected === "campaigns" && <TableCampaigns dataSource={cItems(cStat)} loading={loading} history={props.history}/>}
        {selected === "auto" && <TableAuto dataSource={cItems(cStat)} loading={loading} history={props.history}/>}
        {selected === "operators" && <TableOperators dataSource={oItems(oStat)} loading={loading} history={props.history}/>}
        {selected === "calls" && <TableCalls dataSource={caItems(caStat)} loading={loading} history={props.history}/>}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.app.campaigns,
  cust_id: state.auth.cust_id
});

export default connect(mapStateToProps)(ScreenStats);
