import React from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { Table, Button, Modal, notification } from "antd";
import { columns } from "./data";
// import { filterFormatter } from "@/utils";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { REMOVE_APP_BY_PARAM } from "@/actions/app";
import { call, call_sso } from "@/actions/axios";

const { confirm } = Modal;

const TableComp = (props) => {
  const { roles, history, cust_id, user } = props;
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const dispatch = useDispatch();

  const onEdit = (msisdn) => {
    history.push("/operators/" + msisdn);
  };

  const onDelete = (msisdn) => {
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: "Вы действительно хотите удалить оператора?",
      okText: "Да",
      async onOk() {
        try {
          await dispatch(
            call_sso({
              url: `api/user/service-profile/${msisdn}`,
              method: "DELETE",
            })
          );
          await dispatch(
            call({
              url: `/api/customer/operators/msisdns/assigned/${cust_id}/${msisdn}`,
              method: "DELETE",
            })
          );
          dispatch(REMOVE_APP_BY_PARAM(["operators"], "msisdn", msisdn));
        } catch (e) {
          return notification.info({
            message: "Ошибка!",
            description: "Не удалось удалить оператора",
          });
        }
      },
      onCancel() {},
    });
  };

  const options = {
    group: {
      // filters: filterFormatter(groups, "name"),
      render: (_, item) => item.group && item.group.name,
    },
    role: {
      // filters: roles,
      render: (value) => {
        const role = _.find(roles, { value });
        return role ? role.text : "";
      },
    },
    actions: {
      render: (_, item) => {
        const editAction = (
          <Button
            onClick={() => onEdit(item.msisdn)}
            size="small"
            type="action_edit"
          >
            <EditOutlined />
          </Button>
        );
        const deleteAction = (
          <Button
            onClick={() => onDelete(item.msisdn)}
            size="small"
            type="action_delete"
          >
            <DeleteOutlined />
          </Button>
        );
        return (
          <div className="actions">
            {editAction}
            {user.msisdn === item.msisdn || deleteAction}
          </div>
        );
      },
    },
  };

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.msisdn}
      {...props}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cust_id: state.auth.cust_id,
  roles: state.app.roles,
});

export default connect(mapStateToProps)(TableComp);
