import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Button, Card } from "antd";
import Table from "./Table";
import { SET_APP, GET_SCENARIOS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading } = props;

  const onCreate = () => {
    history.push("/scenarios/new");
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_APP(["scenario"], null));
    dispatch(GET_SCENARIOS());
  }, [dispatch]);

  const backUrl = localStorage.getItem("backUrl");

  return (
    <>
      <PageHeader
        title="Сценарии"
        onBack={() => history.push(backUrl ? backUrl : "/campaings")}
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Добавить сценарий
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.scenarios,
  loading: state.app.scenariosLoading,
});

export default connect(mapStateToProps)(Screen);
