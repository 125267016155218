import React from "react";
import uniqid from "uniqid";
import Immutable from "immutable";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Divider, Form, Input, Modal } from "antd";
import { Col, Row, Select } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import ScenarioPreview from "@/screens/Scenarios/Preview";
import { call } from "@/actions/axios";
import _ from "lodash";

const { confirm } = Modal;
const { Option } = Select;

const DEFAULT_OPTION = () => ({ id: uniqid(), next: 0 });

const DEFAULT_QUESTION = () => ({
  id: uniqid(),
  options: [DEFAULT_OPTION()],
  type: "radio",
});

const Step = (props) => {
  const { history, match, cust_id } = props;
  const [loading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState(null);

  const form = React.useRef();
  const dispatch = useDispatch();

  const isNew = match.params.id === "new";

  React.useEffect(() => {
    const { data, match } = props;
    if (!isNew) {
      const item = _.find(data, { id: parseInt(match.params.id) });
      if (item) {
        form.current.setFieldsValue(item);
      } else history.push("/scenarios/new");
    } else {
      const questions = [DEFAULT_QUESTION()];
      form.current.setFieldsValue({ questions });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async () => {
    const { match } = props;
    const values = await form.current.validateFields();

    setLoading(true);
    // console.log(values);
    if (isNew) {
      await dispatch(
        call({
          url: "/scenarios/" + cust_id,
          method: "POST",
          data: values,
        })
      );
    } else {
      await dispatch(
        call({
          url: `/scenarios/${cust_id}/${match.params.id}`,
          method: "PATCH",
          data: values,
        })
      );
    }
    setLoading(false);

    confirm({
      title: "Сценарий успешно " + (isNew ? "добавлен" : "обновлен"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку сценариев?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
        const questions = [DEFAULT_QUESTION()];
        form.current.setFieldsValue({ questions });
      },
      onCancel() {
        history.push("/scenarios");
      },
    });
  };

  const onPreview = async () => {
    const values = await form.current.validateFields();

    setPreview(values);
  };

  return (
    <>
      <Modal
        title="Предпросмотр"
        visible={!!preview}
        footer={null}
        onCancel={() => setPreview(false)}
        destroyOnClose={true}
      >
        <ScenarioPreview scenario={preview} />
      </Modal>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Название сценария"
              name="name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                disabled={loading}
                placeholder="Не менее 2 и не более 100 символов"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Описание" name="description">
              <Input disabled={loading} placeholder="Описание сценария" />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Список вопросов</Divider>
        <KeyValue loading={loading} collection="questions" form={form} />
        <Divider orientation="left">Завершающий</Divider>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Текст завершения"
              name="finishText"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                disabled={loading}
                placeholder="Введите текст завершения"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onPreview} type="action" style={{ marginLeft: 14 }}>
          Предпросмотр
        </Button>
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const KeyValue = ({ loading, collection, form }) => {
  const onAddOption = (q) => async () => {
    if (loading) return;
    const values = await form.current.getFieldsValue();
    const { [collection]: data } = values;
    data[q].options.push(DEFAULT_OPTION());
    await form.current.setFieldsValue({ [collection]: data });
  };

  const onDeleteOption = (q, o) => async () => {
    if (loading) return;
    const values = await form.current.getFieldsValue();
    const { [collection]: data } = values;
    data[q].options.splice(o, 1);
    await form.current.setFieldsValue({ [collection]: data });
  };

  const onAddQuestion = async () => {
    if (loading) return;
    const values = await form.current.getFieldsValue();
    const { [collection]: data } = values;
    data.push(DEFAULT_QUESTION());
    await form.current.setFieldsValue({ [collection]: data });
  };
  const onDeleteQuestion = (index) => async () => {
    if (loading) return;
    const values = await form.current.getFieldsValue();
    const { [collection]: data } = values;
    const question = data[index];
    data.splice(index, 1);
    const checkData = data.map((q) => {
      const options = q.options.map((o) => {
        if (o.type === question.id) return { ...o, type: "submit" };
        else return o;
      });
      return { ...q, options };
    });
    await form.current.setFieldsValue({ [collection]: checkData });
  };

  return (
    <Form.List name={collection}>
      {(questions) =>
        questions.map((question) => {
          const { key: q, name, fieldKey, ...restField } = question;

          return (
            <React.Fragment key={q}>
              <Divider orientation="left" plain>
                Вопрос {q + 1}
              </Divider>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item name={[name, "id"]} noStyle>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "question"]}
                    fieldKey={[fieldKey, "question"]}
                    rules={[
                      { required: true, message: "Данное поле обязательно" },
                    ]}
                  >
                    <Input
                      placeholder="Введите текст вопроса"
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                  >
                    <Select
                      disabled={loading}
                      placeholder="Выберите тип вопроса"
                    >
                      <Option value="radio">Один из списка</Option>
                      <Option value="check">Несколько из списка</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left" plain>
                Варианты ответов:
              </Divider>
              <Form.List name={[name, "options"]}>
                {(options) => (
                  <>
                    {options.map((option) => {
                      const { key: o, name, fieldKey, ...restField } = option;
                      return (
                        <Row key={o} gutter={32}>
                          <Col span={12}>
                            <Form.Item name={[name, "id"]} noStyle>
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "option"]}
                              fieldKey={[fieldKey, "option"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Данное поле обязательно",
                                },
                              ]}
                              help={
                                o === options.length - 1 && (
                                  <NewOption onAdd={onAddOption(q)} />
                                )
                              }
                            >
                              <Input
                                placeholder="Введите текст ответа"
                                disabled={loading}
                                suffix={
                                  o === 0 || (
                                    <DeleteOutlined
                                      onClick={onDeleteOption(q, o)}
                                    />
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              if (
                                _.isEmpty(currentValues) ||
                                _.isEmpty(prevValues)
                              )
                                return true;
                              const p = Immutable.fromJS(prevValues.questions);
                              const c = Immutable.fromJS(
                                currentValues.questions
                              );
                              return p.equals(c);
                            }}
                          >
                            {({ getFieldValue }) => {
                              const { questions } = getFieldValue();
                              const { type, id } = questions[q];
                              if (type === "check" && o !== 0) return null;
                              return (
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "next"]}
                                    fieldKey={[fieldKey, "next"]}
                                  >
                                    <Select
                                      disabled={loading}
                                      placeholder="Действие"
                                    >
                                      <Option value={0}>
                                        Завершающий текст
                                      </Option>
                                      {questions.map((_q, index) => (
                                        <Option
                                          disabled={_q.id === id}
                                          key={_q.id}
                                          value={_q.id}
                                        >
                                          Вопрос {index + 1}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              );
                            }}
                          </Form.Item>
                        </Row>
                      );
                    })}
                  </>
                )}
              </Form.List>
              <div className="question__buttons">
                <Button onClick={onAddQuestion} type="primary" shape="circle">
                  <PlusOutlined />
                </Button>
                {questions.length > 1 && (
                  <Button
                    onClick={onDeleteQuestion(q)}
                    type="primary"
                    shape="circle"
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </div>
            </React.Fragment>
          );
        })
      }
    </Form.List>
  );
};

const NewOption = ({ onAdd }) => {
  return (
    <div>
      <Button className="info action" type="link" onClick={onAdd}>
        + добавить новый
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.scenarios,
  cust_id: state.auth.cust_id,
});

export default withRouter(connect(mapStateToProps)(Step));
