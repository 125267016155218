import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Popover, Modal } from "antd";
import { notification } from "antd";
import { columns, statuses } from "./data";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { call } from "@/actions/axios";
import {
  REMOVE_APP_BY_PARAM,
  GET_CAMPAIGNS,
  SET_APP_BY_PARAM,
  SET_APP,
} from "@/actions/app";
import { GET_CONTACT_LISTS } from "@/actions/app";
import Actions from "./Actions";
import Socket from "@/ui/Socket";
import Contacts from "./Contacts";

const { confirm } = Modal;

const TableComp = (props) => {
  const cust_id = useSelector((state) => state.auth.cust_id);
  const types = useSelector((state) => state.app.types);
  const { history } = props;
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const [filters, setFilters] = React.useState(null);
  const [sorter, setSorter] = React.useState(null);
  const [campaign, setCampaign] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const onEdit = (item, type) => {
    if (type === "edit") history.push("/campaigns/" + item.id);
    else {
      dispatch(SET_APP(["campaign"], item));
      history.push("/preview/" + item.id);
    }
  };

  const socket = React.useRef();

  React.useEffect(() => {
    socket.current = Socket("campaign");
    socket.current.on("update-campaign", ({ campaign_id, new_status }) => {
      dispatch(
        SET_APP_BY_PARAM(["campaigns"], ["id", campaign_id], {
          campaign_status: new_status,
        })
      );
    });
    return () => {
      if (socket.current) socket.current.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onStatus = (item, new_status) => () => {
    async function setStatus() {
      setLoading(true);
      try {
        await dispatch(
          call({
            url: `/api/customer/campaigns/${cust_id}/${item.id}/status`,
            method: "POST",
            data: { new_status },
          })
        );
        setLoading(false);
        dispatch(GET_CAMPAIGNS());
        socket.current.emit("update-campaign", item.id);
      } catch (e) {
        setLoading(false);
        notification.error({
          message: "Ошибка",
          description: "Не удалось обновить статус кампании",
        });
      }
    }
    try {
      if (new_status === "ON_HOLD" || new_status === "ENDED") {
        let action = statuses[new_status].action;
        return confirm({
          title: "Внимание!",
          icon: <ExclamationCircleOutlined />,
          content: `Вы действительно хотите перевести кампанию - ${action.toLowerCase()}?`,
          okText: "Да",
          async onOk() {
            setStatus();
          },
          onCancel() {},
        });
      } else setStatus();
    } catch (e) {}
  };

  const dispatch = useDispatch();

  const onDelete = (item) => {
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: "Вы действительно хотите удалить кампанию?",
      okText: "Да",
      async onOk() {
        try {
          setLoading(true);
          if (item.campaign_status === "NOT_STARTED") {
            await dispatch(
              call({
                url: `/api/customer/campaigns/${cust_id}/${item.id}`,
                method: "DELETE",
              })
            );
          } else {
            await dispatch(
              call({
                url: `/campaigns/${cust_id}/${item.id}`,
                method: "DELETE",
              })
            );
          }
          setLoading(false);
          dispatch(REMOVE_APP_BY_PARAM(["campaigns"], "id", item.id));
        } catch (e) {
          return notification.error({
            message: "Ошибка!",
            description: "Не удалось удалить кампанию",
          });
        }
      },
      onCancel() {},
    });
  };

  const uploadContacts = (item) => () => {
    dispatch(GET_CONTACT_LISTS());
    setCampaign(item);
  };

  const options = {
    number: {
      render: (text, item) => (
        <Popover
          placement="bottom"
          content={item.numbers.map((n) => (
            <p className="popover-details" key={n.number}>
              {n.number}
            </p>
          ))}
          trigger="hover"
        >
          <Button type="link">{text}</Button>
        </Popover>
      ),
    },
    operator: {
      render: (text, item) => (
        <Popover
          className="details-popover"
          placement="bottom"
          content={item.operators.map((o) => (
            <p
              className="popover-details"
              key={o.id}
            >{`${o.name}/${o.group.name}`}</p>
          ))}
          trigger="hover"
        >
          <Button type="link">{text}</Button>
        </Popover>
      ),
    },
    scenario_id: {
      render: (val) => {
        if (val) {
          const type = _.find(types, { value: parseInt(val) });
          if (type) return type.text;
        }
        return "";
      },
    },
    campaign_status: {
      render: (val) => {
        return statuses[val].text;
      },
    },
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            <Actions
              item={item}
              onEdit={onEdit}
              onDelete={onDelete}
              onStatus={onStatus}
              uploadContacts={uploadContacts}
            />
          </div>
        );
      },
    },
  };

  return (
    <>
      <Table
        size="small"
        className="striped-table"
        columns={columns(options, filters, sorter)}
        rowKey={(row) => row.id}
        {...props}
        loading={loading}
        pagination={pagination}
        onChange={onChange}
      />
      <Contacts campaign={campaign} setCampaign={setCampaign} />
    </>
  );
};

export default TableComp;
