import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Input, Button, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table from "./Table";
import Socket from "@/ui/Socket";
import { SET_APP, GET_AUDIOS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading } = props;

  const onCreate = () => {
    history.push("/audios/new");
  };

  const dispatch = useDispatch();
  const socket = React.useRef();

  React.useEffect(() => {
    dispatch(SET_APP(["notification"], null));
    dispatch(GET_AUDIOS());
  }, [dispatch]);

  React.useEffect(() => {
    socket.current = Socket("audios");
    socket.current.on("audio-updated", () => {
      dispatch(GET_AUDIOS());
    });
    return () => {
      if (socket.current) socket.current.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeader
        title="Аудиоролики"
        subTitle={<SearchInput />}
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Добавить аудиоролик
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const SearchInput = () => (
  <Input
    placeholder="Поиск"
    suffix={<SearchOutlined className="search-icon" />}
  />
);

const mapStateToProps = (state) => ({
  data: state.app.audios,
  loading: state.app.audiosLoading,
});

export default connect(mapStateToProps)(Screen);
