import React from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { call } from "@/actions/axios";
import { SET_APP } from "@/actions/app";

export default function useActiveTimer() {
  const timer = React.useRef();

  const dispatch = useDispatch();
  const active = useSelector((state) => state.app.active);
  const request = useSelector((state) => state.app.request);
  const user = useSelector((state) => state.auth.user);

  const resetCall = async () => {
    const { msisdn } = user;
    await dispatch(
      call({
        url: `/lists/${msisdn}/reset`,
        method: "POST",
      })
    );
    // const newData = _.filter(props.dataSource, (o) => o.external_id !== active);
    // props.setDataSource(newData);
    await dispatch(SET_APP(["active"], null));
    localStorage.setItem("callTime", null);
    // notification.error({
    //   message: "Ошибка",
    //   description: "Не удалось дождаться ответа от сервера",
    // });
  };

  React.useEffect(() => {
    const checkTime = async () => {
      const TIMEOUT_SECONDS = 30;
      if (active && request) {
        const callTime = await localStorage.getItem("callTime");
        if (!callTime || timer.current) return;
        timer.current = setInterval(() => {
          const currentTime = new Date().getTime() - callTime;
          if (currentTime / 1000 > TIMEOUT_SECONDS) {
            clearInterval(timer.current);
            timer.current = null;
            resetCall();
          }
        }, 1000);
      }
    };
    checkTime();
  }, [active, request]); // eslint-disable-line react-hooks/exhaustive-deps
}
