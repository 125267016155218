const defaults = {
  // baseURL: "http://localhost:8080/",
    baseURL: "https://dev.3beep.io/",
  SSO_URL: "https://pre-accounts.kcell.kz/",
  SSO_API_URL: "https://accounts-admin-stage.kcell.kz/",
  SSO_USERNAME: "app_autodialer_backend",
  SSO_PASSWORD: "Urhhhf$$1884NimH",
  color: "#6537ED",
  types: {
    AUTOINFORMATOR: "1",
    OPERATORS: "2",
    TARGETCONTACT: "3",
    AUTOINFORMATOR_DTMF: "4",
  },
  statuses: (val) => {
    if (val === "NOT_DIALED")
      return { status: "Готов к звонку", type: "success" };
    else if (val === "DIALING")
      return { status: "Идет набор", type: "success" };
    else if (val === "CONVERSATION")
      return { status: "Разговор", type: "processing" };
    else if (val === "ANSWERED")
      return { status: "Отвечен", type: "processing" };
    else if (val === "NO_ANSWER")
      return { status: "Не отвечает", type: "warning" };
    else if (val === "BUSY") return { status: "Занят", type: "warning" };
    else if (val === "FAILED") return { status: "Ошибка", type: "error" };
    else if (val === "UNKNOWN")
      return { status: "Неизвестная ошибка", type: "default" };
    return { status: "" };
  },
};

export default defaults;
