import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

export default function useActiveConversation(history) {
  const data = useSelector((state) => state.app.armData);
  let active;
  if (data && data.abonents) {
    active = _.find(data.abonents, { dialing_status: "CONVERSATION" });
  }

  React.useEffect(() => {
    if (active) history.push("/arm/call/" + active.id);
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps
}
