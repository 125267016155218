import React from "react";

const SelectBoxSelectedItem = ({
  data,
  value,
  children,
  defaultName,
  spanClassName,
  selectInput,
}) => {
  let arrowIcon = selectInput ? "fa-angle-down float-right" : "fa-caret-down";
  return (
    children || (
      <span className={spanClassName}>
        {data?.find((it) => it.value === value)?.name || defaultName}
        <i className={`fa ${arrowIcon} ml-2`} />
      </span>
    )
  );
};

const SelectBoxItem = ({ value, name, onSelect, isActive }) => (
  <li
    onClick={onSelect(value)}
    key={`${name}-${value}`}
    style={{ cursor: "pointer" }}
    className={`list-unstyled${isActive ? " is-active" : ""}`}
  >
    {name}
  </li>
);

const SelectBoxList = ({
  ulClassName,
  header,
  data,
  onSelect,
  currentValue,
}) => (
  <ul className={ulClassName}>
    {header && (
      <li className="title list-unstyled">
        {header}
        <hr />
      </li>
    )}
    {data?.map((it) => (
      <SelectBoxItem
        key={`${it.name}-${it.value}`}
        value={it.value}
        isActive={currentValue === it.value}
        name={it.name}
        onSelect={onSelect}
      />
    ))}
  </ul>
);

class SelectBox extends React.Component {
  state = { isActive: false };

  componentDidMount() {
    window.addEventListener("click", this.onOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onOutsideClick);
  }

  onOutsideClick = (evt) =>
    this.setState({ isActive: this.selectbox.contains(evt.target) });

  onSelect = (value) => () => {
    const { onSelect } = this.props;
    onSelect(value);
    this.setState({ isActive: false });
  };

  bindRef = (selectbox) => {
    this.selectbox = selectbox;
  };

  render() {
    const {
      sectionClassName,
      ulClassName,
      header,
      data,
      value,
      children,
      defaultName,
      spanClassName,
      selectInput,
    } = this.props;
    const { isActive } = this.state;
    return (
      <section className={sectionClassName || ""} ref={this.bindRef}>
        <SelectBoxSelectedItem
          data={data}
          value={value}
          defaultName={defaultName}
          spanClassName={spanClassName}
          selectInput={selectInput}
        >
          {children}
        </SelectBoxSelectedItem>
        {isActive && (
          <SelectBoxList
            onSelect={this.onSelect}
            ulClassName={ulClassName}
            header={header}
            data={data}
            currentValue={value}
          />
        )}
      </section>
    );
  }
}

export default SelectBox;
