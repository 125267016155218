export default {
  kcellBusiness: {
    ru: "Kcell Бизнес",
    en: "Kcell Business",
    kk: "Kcell Бизнес",
  },
  bin: {
    ru: "БИН ",
    en: "BIN ",
    kk: "БИН ",
  },
  paymentTerms: {
    ru: "Информация для оплаты",
    en: "Payment terms",
    kk: "Төлем ақпараты",
  },
  eRegister: {
    ru: "Электронный реестр",
    en: "E-register",
    kk: "Электрондық тізім",
  },
  applicationSamples: {
    ru: "Образцы заявлений",
    en: "Applications samples",
    kk: "Өтініш үлгілері",
  },
  automaticBalanceNotification: {
    ru: "Автоинформирование о балансе",
    en: "Automatic balance notification",
    kk: "Теңгерім туралы автохабарлау",
  },
  contacts: {
    ru: "Контакты",
    en: "Contacts",
    kk: "Байланыстар",
  },
  footerText: {
    ru:
      " © АО Kcell 2000-2017. Государственная лицензия № ДС0000270 от 08.06.1998, " +
      "          бессрочная, выдана Министерством транспорта и коммуникаций Республики Казахстан",
    en:
      "© Kcell JSC 2000–2017. State license No. ДС0000270 of 08.06.1998, perpetual," +
      " issued by the Ministry of Transport and Communications of the Republic of Kazakhstan.",
    kk:
      "© «Кселл» АҚ 2000–2017. Қазақстан Республикасының Көлік және коммуникация " +
      "министрлігі 08.06.1998 берген мемлекеттік лицензия № ДС0000270, мерзімсіз.",
  },
  selectCompany: {
    ru: "Выбор кaмпании",
    en: "Select company",
    kk: "кaмпания таңдау",
  },
  selectLang: {
    ru: "Выбор языка",
    en: "Choose lang",
    kk: "Тілді таңдау",
  },
  chooseService: {
    ru: "Выбор услуги",
    en: "Choose service",
    kk: "Қызметті таңдау",
  },
  myProfile: {
    ru: "Мой профиль",
    en: "My profile",
    kk: "Менің профилім",
  },
  sipProfile: {
    ru: "SIP профиль",
    en: "SIP profile",
    kk: "SIP профилі",
  },
  exit: {
    ru: "Выйти",
    en: "Exit",
    kk: "Шығу",
  },
  search: {
    ru: "Поиск",
    en: "Search",
    kk: "Іздеу",
  },
};
