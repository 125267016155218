import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Modal, Form, Input, Button, notification } from "antd";
import SelectBox from "./SelectBox";
import Message from "./Message";
import defaults from "@/defaults";
import { useKeycloak } from "@react-keycloak/web";
import { call } from "@/actions/axios";
import { SET_AUTH, CHECK_ACCESS } from "@/actions/auth";
import { LOGOUT } from "@/actions/auth";

class Header extends React.Component {
  changeLang = (value) => {
    this.props.SET_AUTH(["lang"], value);
  };

  changeCustomer = async (value) => {
    const { userInfo, history } = this.props;
    const customer = _.find(userInfo.customers, { idDocNum: value });
    this.props.SET_AUTH(["customer"], customer);
    const access = await this.props.CHECK_ACCESS(
      customer,
      userInfo.msisdn,
      history
    );
    if (!access) history.push("/access");
    else history.push("/campaigns");
  };

  renderLangSelectBox = ({ lang = "ru" }) => (
    <SelectBox
      sectionClassName="d-inline-block position-relative ml-4"
      value={lang}
      header={Message.selectLang[lang]}
      ulClassName="t-light mb-0 selectbox bg-white text-midnight-gray profile-option-selectbox"
      spanClassName="selectbox-title text-left cursor-pointer"
      data={[
        { name: "РУС", value: "ru" },
        { name: "ENG", value: "en" },
        { name: "ҚАЗ", value: "kk" },
      ]}
      onSelect={this.changeLang}
    />
  );

  renderChangeCustomerSelectBox = ({
    customer = { idDocNum: "700700700700" },
    userInfo = {
      firstName: "Admin Admin",
      userId: "test",
      customers: [
        { alias: "ТОО Кабинет", idDocNum: "700700700700" },
        { alias: "ТОО Test", idDocNum: "700700700701" },
      ],
    },
    lang = "ru",
  }) => {
    if (!(userInfo && customer && userInfo.customers)) {
      return null;
    }
    const customers = userInfo.customers;
    return (
      customers[0] && (
        <SelectBox
          value={customer.idDocNum}
          sectionClassName="d-inline-block position-relative pr-2 profile-name"
          header={Message.selectCompany[lang]}
          ulClassName="t-light mb-0 selectbox bg-white text-midnight-gray"
          spanClassName="selectbox-title text-left cursor-pointer"
          data={customers.map((it) => ({
            name: it.alias || Message.bin[lang] + it.idDocNum,
            value: it.idDocNum,
          }))}
          onSelect={this.changeCustomer}
        />
      )
    );
  };

  renderServiceMenu({ customer, lang = "ru" }) {
    const { services } = customer;
    const initialValue = services.autodialer ? services.autodialer.url : "/";
    return (
      <div className="d-inline-block">
        <span className="divider divider-lg d-none d-lg-inline" />
        <SelectBox
          sectionClassName="position-relative ml-4 d-none d-lg-inline-block"
          header={Message.chooseService[lang]}
          spanClassName="selectbox-title text-left cursor-pointer menu align-middle"
          ulClassName="t-light mb-0 selectbox bg-white text-midnight-gray"
          data={Object.keys(services).map((alias) => ({
            name: services[alias].name[lang],
            value: services[alias].url,
          }))}
          value={initialValue}
          defaultName={Message.chooseService[lang]}
          onSelect={(value) => (document.location = value)}
        />

        <nav className="d-inline-block align-middle d-lg-none">
          <input type="checkbox" id="nav" className="d-none" />
          <label htmlFor="nav" className="nav-open">
            <i className="bg-white" />
            <i className="bg-white" />
            <i className="bg-white" />
          </label>
          <div className="nav-container clearfix bg-purple">
            <ul className="mb-0 pl-0 menu">
              {Object.keys(services).map((alias, index) => (
                <li className="d-inline-block" key={index}>
                  <a href={services[alias].url}>{services[alias].name[lang]}</a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    );
  }

  render() {
    const { customer, userInfo } = this.props;
    if (!customer || !userInfo) return null;
    return (
      <header className="global-header bg-purple">
        <div className="d-flex justify-content-between">
          <div>
            <a href="https://www.kcell.kz" className="d-inline-block">
              <img
                className="logo d-none d-lg-inline-block mr-4"
                src="/kcell_logo_white.svg"
                alt="logo"
                height="35"
              />
            </a>
            {this.renderServiceMenu(this.props)}
          </div>
          <div className="d-flex align-items-center">
            {this.renderChangeCustomerSelectBox(this.props)}
            <UserMenu {...this.props} />
            {this.renderLangSelectBox(this.props)}
          </div>
        </div>
      </header>
    );
  }
}

const UserMenu = ({ userInfo, cust_id, api_user, lang = "ru" }) => {
  const form = React.useRef();
  const isFirstTime = api_user && api_user.sip_account_created === 0;
  const [sipModal, setSipModal] = React.useState(isFirstTime);
  const [loading, setLoading] = React.useState(false);
  const [keycloak] = useKeycloak();
  const dispatch = useDispatch();
  const logout = async () => {
    if (keycloak.authenticated) {
      await dispatch(LOGOUT());
      await keycloak.logout();
    }
  };
  const onSave = async () => {
    setLoading(true);
    const { msisdn } = userInfo;
    const values = await form.current.validateFields();
    // console.log(msisdn, values, cust_id);
    try {
      await dispatch(
        call({
          url: `/api/admin/operators/msisdns/assigned/${cust_id}/${msisdn}/change_sip_password`,
          method: "POST",
          data: { sip_password: values.password },
        })
      );
      notification.success({
        message: "Успешно!",
        description: "Пароль для SIP изменен",
      });
    } catch (e) {
      notification.error({
        message: "Ошибка!",
        description: "Не удалось изменить пароль для SIP",
      });
    }

    setLoading(false);
    setSipModal(false);
  };

  if (userInfo && userInfo.userId) {
    return (
      <SelectBox
        sectionClassName="d-inline-block position-relative cursor-pointer"
        ulClassName="t-light mb-0 selectbox bg-white text-midnight-gray profile-option-selectbox"
        header={
          <div className="d-flex">
            <div className="icon bg-purple text-white">
              {userInfo.firstName && userInfo.firstName.charAt(0)}
            </div>
            {userInfo.firstName} {userInfo.lastName}
          </div>
        }
        data={[
          { name: Message.myProfile[lang], value: 1 },
          { name: Message.sipProfile[lang], value: 2 },
          { name: Message.exit[lang], value: 3 },
        ]}
        onSelect={(value) => {
          if (value === 1) document.location = `${defaults.SSO_URL}settings`;
          else if (value === 2) setSipModal(true);
          else if (value === 3) logout();
        }}
      >
        <div className="profile-icon bg-white rounded-circle text-purple text-center d-inline-block">
          {userInfo.firstName && userInfo.firstName.charAt(0)}
        </div>
        <div className="profile-option bg-white rounded-circle text-purple text-center">
          <i className="fa fa-angle-down" />
        </div>
        <Modal
          title={Message.sipProfile[lang]}
          visible={sipModal}
          closable={!isFirstTime}
          onCancel={() => (!isFirstTime ? setSipModal(false) : false)}
          destroyOnClose={true}
          footer={
            <>
              {!isFirstTime && (
                <Button onClick={() => setSipModal(false)}>Отмена</Button>
              )}
              <Button type="primary" loading={loading} onClick={onSave}>
                Сохранить
              </Button>
            </>
          }
        >
          <Form layout="vertical" ref={form}>
            <Form.Item
              label="Новый пароль"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Для смены пароля необходимо ввести текст!",
                },
                {
                  min: 10,
                  message: "Пароль должен содержать не менее 10 символов",
                },
              ]}
              hasFeedback
            >
              <Input
                disabled={loading}
                type="password"
                placeholder="Введите пароль"
              />
            </Form.Item>
            <Form.Item
              label="Новый пароль повторно"
              name="password_confirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Введите новый пароль повторно!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Введенные пароли не совпадают");
                  },
                }),
              ]}
            >
              <Input
                disabled={loading}
                type="password"
                placeholder="Введите пароль еще раз"
              />
            </Form.Item>
          </Form>
        </Modal>
      </SelectBox>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
  userInfo: state.auth.user,
  api_user: state.auth.api_user,
  cust_id: state.auth.cust_id,
  customer: state.auth.customer,
});

export default withRouter(
  connect(mapStateToProps, { SET_AUTH, CHECK_ACCESS })(Header)
);
