import React from "react";
import _ from "lodash";
import XLSX from "xlsx";
import { Button, Radio, Upload } from "antd";
import { Table, Alert, notification } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { MaskedInput } from "@/ui";

const List = (props) => {
  const { contacts, setContacts, loading } = props;

  const [tab, setTab] = React.useState("manual");
  const [phone, setPhone] = React.useState("");

  if (!contacts) return null;

  const onAdd = (phone) => {
    phone = phone.replace(/[^0-9]/g, "");
    if (phone.length !== 11) return;
    const isDuplicate = _.find(contacts, { msisdn: phone });
    if (isDuplicate)
      notification.warn({
        message: "Номер уже добавлен",
        description: "Введенный номер уже присутствует в списке",
      });
    else
      setContacts([
        ...contacts,
        { external_id: contacts.length + 1, msisdn: phone },
      ]);
    setPhone("");
  };

  const onDelete = (key) => {
    contacts.splice(key, 1);
    // console.log(contacts);
    setContacts([...contacts]);
  };

  const columns = [
    { title: "Номер абонента", dataIndex: "msisdn" },
    {
      title: "Действия",
      dataIndex: "actions",
      width: 150,
      render: (_, __, key) => {
        return (
          <div className="actions">
            <Button
              onClick={() => onDelete(key)}
              size="small"
              disabled={loading}
              type="action_delete"
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];
  const options = [
    { label: "Вручную", value: "manual" },
    { label: "Из файла", value: "file" },
  ];

  const uploadProps = {
    multiple: false,
    disabled: loading,
    customRequest: () => false,
    beforeUpload: async (f) => {
      // console.log(f);
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        try {
          const { result } = event.target;

          // const dt = await XLSX.readFile(f.path);
          const dt = XLSX.read(result, { type: "binary" });
          const first_worksheet = dt.Sheets[dt.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });

          let msisdn = [];
          let duplicates = 0;
          let n = 1;
          let description = [`Общее количество: ${data.length - 1}`];
          // console.log(data);
          data.forEach((row) => {
            if (row.length === 0) return;
            let [phone] = row;
            phone = phone.toString().replace(/[^0-9]/g, "");
            if (phone.length !== 11) return;

            const isDuplicate = _.find(msisdn, { msisdn: phone });
            if (isDuplicate) return duplicates++;

            msisdn.push({ external_id: n, msisdn: phone });
            n++;
          });

          description.push(`Загружено: ${msisdn.length}`);
          const totalErrors = data.length - msisdn.length - 1;
          if (totalErrors !== 0)
            description.push(`Некорректный формат: ${totalErrors}`);
          if (duplicates) description.push(`Дубликатов: ${duplicates}`);
          const newcontacts = _.uniqBy([...contacts, ...msisdn], "msisdn");
          // console.log(newcontacts);
          setContacts(newcontacts);
          setPhone("");
          notification.success({
            message: "Файл успешно подгружен",
            description: description.map((text) => (
              <React.Fragment key={text}>
                {text}
                <br />
              </React.Fragment>
            )),
          });
          return false;
        } catch (e) {
          notification.error({
            message: "Ошибка при загрузке файла",
            description:
              "Некорректный файл. Проверьте данные и попробуйте еще раз",
          });
          // console.log(e.message);
          // console.log("file type is incorrect!");
        }
      };
      fileReader.readAsBinaryString(f);
      return false;
    },
    showUploadList: false,
  };

  return (
    <>
      <Radio.Group
        options={options}
        onChange={(e) => setTab(e.target.value)}
        value={tab}
        optionType="button"
        buttonStyle="solid"
        disabled={loading}
      />
      <div className="tab-content">
        {tab === "manual" && (
          <MaskedInput
            enterButton="Добавить"
            onSearch={onAdd}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            disabled={loading}
            type="search"
            mask="+7 711 111 11 11"
          />
        )}
        {tab === "file" && (
          <Upload {...uploadProps}>
            <Button>
              <UploadOutlined /> Загрузить файл с номерами
            </Button>
          </Upload>
        )}
      </div>
      <Alert
        message={`Количество контактов: ${contacts.length}`}
        type="info"
        style={{ marginBottom: 12 }}
      />
      <Table
        size="small"
        dataSource={contacts}
        columns={columns}
        loading={loading}
        rowKey={(row) => row.external_id}
      />
    </>
  );
};

export default List;
