import _ from "lodash";
import defaults from "@/defaults";

export const columns = (campaign) => (options, filters, sorter) => {
  const msisdn = {
    title: "Номер абонента",
    dataIndex: "msisdn",
  };
  let data = [
    {
      title: "ID абонента",
      dataIndex: "id",
    },
  ];

  if (campaign) {
    if (
      campaign.scenario_id &&
      campaign.scenario_id.toString() === defaults.types.OPERATORS
    ) {
      data.push(msisdn);
    }
  }

  const extra = [
    {
      title: "Статус",
      dataIndex: "dialing_status",
    },
    {
      width: 250,
      title: "Действия",
      dataIndex: "actions",
    },
  ];

  data = [...data, ...extra];
  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          if (a[dataIndex] && b[dataIndex])
            return a[dataIndex].length - b[dataIndex].length;
        };
      }
    });
  }

  return data;
};
