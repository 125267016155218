import React from "react";
import { connect } from "react-redux";
import { Empty, Button } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import "./index.less";

const Screen = (props) => {
  const [keycloak] = useKeycloak();
  const { history, message } = props;
  const logout = async () => {
    if (keycloak.authenticated) await keycloak.logout();
  };
  React.useEffect(() => {
    if (!keycloak.authenticated) history.push("/");
  }, [keycloak, history]);
  return (
    <div style={{ marginTop: 40 }}>
      <Empty
        image={require("@/assets/images/access.png")}
        imageStyle={{ height: 250 }}
        description={
          <>
            <span className="header">
              {message ? message : "Неизвестная ошибка"}
            </span>
            <br />
            <span>
              Доступ закрыт, обратитесь к администратору Kcell по адресу{" "}
              <a href="mailto:autodialer@kcell.kz">autodialer@kcell.kz</a>
            </span>
          </>
        }
      >
        <Button type="primary" onClick={logout}>
          Выход
        </Button>
      </Empty>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.auth.message,
});

export default connect(mapStateToProps)(Screen);
