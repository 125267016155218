import React from "react";
import { useDispatch, connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { SET_AUTH, CHECK_ACCESS } from "@/actions/auth";
import { call_sso } from "@/actions/axios";
import { Loading } from "@/ui";
import { useKeycloak } from "@react-keycloak/web";

import "./index.less";

const Screen = (props) => {
  const { history, api_user } = props;
  const [access, setAccess] = React.useState(false);
  const dispatch = useDispatch();
  const [keycloak, initialized] = useKeycloak();

  const updater = React.useRef();

  React.useEffect(() => {
    const update = async () => {
      const profile = await keycloak.loadUserProfile();
      let customer;

      try {
        let { data } = await dispatch(
          call_sso({
            url: `/api/user/${profile.username}`,
          })
        );

        // if (data === "SSO_UNAVAILABLE") data = require("./defaults").default;
        if (data) {
          dispatch(SET_AUTH(["token"], keycloak.token));
          dispatch(SET_AUTH(["user"], data));
          if (data.customers.length > 0) {
            dispatch(SET_AUTH(["customers"], data.customers));
            customer = data.customers[0];
            dispatch(SET_AUTH(["customer"], customer));
            return await dispatch(
              CHECK_ACCESS(customer, profile.username, history)
            );
          } else {
            dispatch(SET_AUTH(["message"], "Не найдены закрепленные кaмпании"));
            return false;
          }
        }
      } catch (e) {
        // console.log(e.message);
        dispatch(SET_AUTH(["message"], "Ошибка авторизации"));
        history.push("access");
        return false;
      }
    };
    const init = async () => {
      const access = await update();
      // console.log(access);
      setAccess(access);
      const updateTime = 10;
      updater.current = setInterval(async () => {
        try {
          const isUpdated = await keycloak.updateToken(updateTime);
          if (isUpdated) await update();
        } catch (e) {
          clearInterval(updater.current);
          keycloak.logout();
        }
      }, updateTime * 1000);
    };

    if (initialized) {
      if (keycloak.token) init();
      else {
        dispatch(SET_AUTH(["token"], null));
        dispatch(SET_AUTH(["user"], null));
        keycloak.login();
      }
    }
  }, [initialized, keycloak, dispatch, history]);

  if (api_user && access) {
    let pathname = "campaigns";
    if (api_user.role === "OPERATOR") pathname = "arm";
    if(props.location && props.location.state && props.location.state.from && props.location.state.from.pathname !== "/"){
      console.log('FROM', props.location.state.from.pathname);
      pathname = props.location.state.from.pathname;
    }
    return (
      <Redirect
        to={{
          pathname,
          state: { from: props.location },
        }}
      />
    );
  }
  return <Loading />;
};

const mapStateToProps = (state) => ({
  api_user: state.auth.api_user,
});

export default connect(mapStateToProps)(Screen);
