import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CAMPAIGN_DETAILED } from "@/actions/app";
import { SET_APP } from "@/actions/app";

export default function useFetchingCampaign(api_user) {
  const [ready, setReady] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.app.campaign);

  React.useEffect(() => {
    const fetchData = async () => {
      const { id_of_campaign_assigned_to } = api_user;
      if (!id_of_campaign_assigned_to)
        return await dispatch(SET_APP(["campaign"], null));

      if (campaign && campaign.id === id_of_campaign_assigned_to) return;

      setFetching(true);

      if (fetching) return;
      const data = await dispatch(
        GET_CAMPAIGN_DETAILED(id_of_campaign_assigned_to)
      );

      await dispatch(
        SET_APP(["campaign"], { ...data, id: id_of_campaign_assigned_to })
      );
      setFetching(false);
      setReady(true);
    };
    fetchData();
  }, [api_user, campaign]); // eslint-disable-line react-hooks/exhaustive-deps

  return { campaign, ready };
}
