import _ from "lodash";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Название",
      dataIndex: "name",
    },
    {
      title: "Описание",
      dataIndex: "description",
    },
    {
      title: "Действия",
      dataIndex: "actions",
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          if (a[dataIndex] && b[dataIndex])
            return a[dataIndex].length - b[dataIndex].length;
        };
      }
    });
  }

  return data;
};
