import Immutable from "immutable";
import _ from "lodash";

const INITIAL_STATE = {
  step: 1,
  activeCampaigns: [],
  campaign: null,
  campaigns: [],
  campaignsLoading: false,
  operators: [],
  operatorsLoading: false,
  groups: [],
  groupsLoading: false,
  aons: [],
  aonsLoading: false,
  agroups: [],
  agroupsLoading: false,
  calendars: [],
  calendarsLoading: false,
  dialingNow: false,
  scenarios: [],
  scenariosLoading: false,
  audios: [],
  audiosLoading: false,
  contacts: [],
  contactsLoading: false,
  reportOperators: null,
  reportCampaigns: null,
  reportLoading: false,
  languages: [
    { text: "Русский", value: "ru" },
    { text: "Казахский", value: "kz" },
  ],
  roles: [
    { text: "Оператор", value: "OPERATOR" },
    { text: "Супервайзер", value: "SUPERVISOR" },
  ],
  types: [
    { text: "Обзвон операторами", value: 2 },
    { text: "Таргетированный контакт", value: 3 },
    { text: "Проигрывание автоинформатора", value: 1 },
    { text: "Проигрывание автоинформатора DTMF", value: 4 },
  ],
  priorities: [
    { text: "Низкий", value: "low" },
    { text: "Средний", value: "medium" },
    { text: "Высокий", value: "high" },
  ],
  numbers: [
    { id: 1, number: "3366" },
    { id: 2, number: "+7 777 777 77 77" },
    { id: 3, number: "6909" },
  ],
};

export default (state = INITIAL_STATE, action) => {
  var newState, $var;
  switch (action.type) {
    case "SET_APP":
      newState = Immutable.fromJS(state).setIn(action.path, action.value);
      return newState.toJS();
    case "SET_APP_BY_PARAM":
      newState = Immutable.fromJS(state);
      if (action.param.length !== 2) return newState.toJS();
      const [name, value] = action.param;
      $var = newState.getIn(action.path).toJS();
      $var = _.findIndex($var, { [name]: value });
      if ($var === -1) return newState.toJS();
      Object.keys(action.value).forEach((key) => {
        newState = newState.setIn(
          [...action.path, $var, key],
          action.value[key]
        );
      });
      return newState.toJS();
    case "SET_APPS":
      newState = Immutable.fromJS(state);
      if (action.arr && action.arr.length !== 0) {
        action.arr.forEach((values) => {
          newState = newState.setIn(values.path, values.value);
        });
      }
      return newState.toJS();
    case "PUSH_APP":
      newState = Immutable.fromJS(state).updateIn(action.path, (data) =>
        data.push(action.value)
      );
      return newState.toJS();
    case "UNSHIFT_APP":
      newState = Immutable.fromJS(state).updateIn(action.path, (data) =>
        data.unshift(action.value)
      );
      return newState.toJS();
    case "REMOVE_APP_BY_PARAM":
      newState = Immutable.fromJS(state);
      $var = newState
        .getIn(action.path)
        .filter((o) => o.get(action.key) !== action.value);
      return newState.setIn(action.path, $var).toJS();
    case "INIT":
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
