import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { PageHeader, Input, Dropdown, Card, Menu, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SET_APP, GET_OPERATORS } from "@/actions/app";
import Table from "./Table";
import "./index.less";

const Screen = (props) => {
  const { data, loading, history, roles, customerInfo } = props;
  const [search, setSearch] = React.useState();

  const onCreate = () => {
    if (data.length >= customerInfo.operators_allowed_to_create) {
      return notification.error({
        message: "Лимит ограничен!",
        description: "Вы исчерпали максимальный лимит по количеству операторов",
      });
    }
    props.history.push("/operators/new");
  };

  const onMenu = () => {
    localStorage.setItem("backUrl", "/operators");
    history.push("/groups");
  };

  const menu = (
    <Menu onClick={onMenu}>
      <Menu.Item key="o-groups">Список групп</Menu.Item>
    </Menu>
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_APP(["operator"], null));
    dispatch(GET_OPERATORS());
  }, [dispatch]);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const items = () => {
    if (search) {
      const filters = [
        "represented_name",
        "role",
        "operator_group_name",
        "msisdn",
        "email",
      ];
      return _.filter(data, (o) => {
        let exists = false;
        for (let i = 0; i < filters.length; i++) {
          const input = filters[i];
          const item = o[input];
          if (item) {
            let str;
            if (input === "role") {
              const role = _.find(roles, { value: item });
              if (role) str = role.text.toLocaleLowerCase();
            } else if (input === "msisdn") {
              str = item.replace(/\D/g, "");
            } else {
              str = _.toString(item).toLocaleLowerCase();
            }
            if (str && str.indexOf(search.toLocaleLowerCase()) !== -1) {
              exists = true;
              break;
            }
          }
        }
        return exists;
      });
    }
    return data;
  };
  // console.log(search, data);

  return (
    <>
      <PageHeader
        title="Список операторов"
        subTitle={<SearchInput onChange={onSearch} />}
        extra={[
          <Dropdown.Button
            key="create"
            type="success"
            onClick={onCreate}
            overlay={menu}
          >
            Добавить оператора
          </Dropdown.Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={items()} loading={loading} history={history} />
      </Card>
    </>
  );
};

const SearchInput = (props) => (
  <Input
    placeholder="Поиск"
    suffix={<SearchOutlined className="search-icon" />}
    {...props}
  />
);

const mapStateToProps = (state) => ({
  data: state.app.operators,
  customerInfo: state.auth.customerInfo,
  loading: state.app.operatorsLoading,
  roles: state.app.roles,
});

export default connect(mapStateToProps)(Screen);
