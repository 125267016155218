import React from "react";
import _ from "lodash";
import {Loading} from "@/ui";
import {connect} from "react-redux";
import {PageHeader, Typography, Col, Card} from "antd";
import {Step1, Step2} from "./Steps";
import "./index.less";

const {Text} = Typography;

export const Context = React.createContext();

const defaults = {
  priority: {text: "Средний", value: "medium"},
  recording: 0,
};

const Screen = (props) => {
  const {step: preSavedStep} = props;
  const [step, setStep] = React.useState(preSavedStep);
  const [values, setValues] = React.useState(null);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const {data, match, campaign, history} = props;
    const item = _.find(data, {id: match.params.id});
    if (item) {
      // if (item.campaign_status !== "NOT_STARTED") history.push("/campaigns");
      setValues(item);
    } else if (campaign) {
      setValues(campaign);
    }
    else {
      setValues(defaults);
    }
    setReady(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const context = {setStep, values, setValues};

  if (!ready) return <Loading/>;
  return (
    <Col span={16}>
      <PageHeader
        title="Создать кампанию"
        extra={[<Text key="step" strong>{`Шаг ${step} из 2`}</Text>]}
      />
      <Context.Provider value={context}>
        <Card bordered={false} className="create-container">
          {step === 1 && <Step1/>}
          {step === 2 && <Step2/>}
        </Card>
      </Context.Provider>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.campaigns,
  campaign: state.app.campaign,
  step: state.app.step,
});

export default connect(mapStateToProps)(Screen);
