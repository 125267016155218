import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";

import { PrivateRoute } from "@/utils/keycloak";

import Campaigns from "./screens/Campaigns";
import CampaignNew from "./screens/CampaignNew";
import Operators from "./screens/Operators";
import OperatorNew from "./screens/OperatorNew";
import Groups from "./screens/Groups";
import GroupNew from "./screens/GroupNew";
import Scenarios from "./screens/Scenarios";
import ScenarioNew from "./screens/ScenarioNew";
import Statuses from "./screens/Statuses";
import StatusNew from "./screens/StatusNew";
import Audios from "./screens/Audios";
import AudioNew from "./screens/AudioNew";
import Calendars from "./screens/Calendars";
import CalendarNew from "./screens/CalendarNew";
import Integrations from "./screens/Integrations";
import Arm from "./screens/Arm";
import Call from "./screens/Call";
import Reports from "./screens/Reports";
import Stats from "./screens/Stats";
import Init from "./screens/Init";
import Access from "./screens/Access";
import CampaignPreview from "./screens/CampaignPreview";
import NotFound from "./screens/NotFound";

import { Header } from "./ui";
import KCELL from "./ui/KCELL";

import "antd/dist/antd.less";
import "./App.less";

const { Content } = Layout;

const App = () => (
  <BrowserRouter>
    <KCELL.Header />
    <Layout className="main__wrapper">
      <Layout>
        <Header />
        <Content className="main__content__layout">
          <Switch>
            <Route exact path="/" component={Init} />
            <PrivateRoute
              exact
              path="/campaigns"
              component={Campaigns}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/campaigns/:id"
              component={CampaignNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/preview/:id"
              component={CampaignPreview}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/operators"
              component={Operators}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/operators/:id"
              component={OperatorNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/groups"
              component={Groups}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/groups/:id"
              component={GroupNew}
              roles={["SUPERVISOR"]}
            />
            <Route
              exact
              path="/scenarios"
              component={Scenarios}
              roles={["SUPERVISOR"]}
            />
            <Route
              exact
              path="/scenarios/:id"
              component={ScenarioNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/statuses"
              component={Statuses}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/statuses/:id"
              component={StatusNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/audios"
              component={Audios}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/audios/:id"
              component={AudioNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/calendars"
              component={Calendars}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/calendars/:id"
              component={CalendarNew}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/integrations"
              component={Integrations}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/arm"
              component={Arm}
              roles={["SUPERVISOR", "OPERATOR"]}
            />
            <Route
              exact
              path="/arm/call/:id"
              component={Call}
              roles={["SUPERVISOR"]}
            />
            <PrivateRoute
              exact
              path="/reports"
              component={Reports}
              roles={["SUPERVISOR", "OPERATOR"]}
            />
            <PrivateRoute
              exact
              path="/stats"
              component={Stats}
              roles={["SUPERVISOR", "OPERATOR"]}
            />
            <Route path="/access" component={Access} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
    <KCELL.Footer />
  </BrowserRouter>
);

export default App;
