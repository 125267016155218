import React from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import { Col, Row, Select, Modal } from "antd";
import { MaskedInput } from "@/ui";
import { CheckCircleOutlined } from "@ant-design/icons";
import { SET_APP, SET_APPS } from "@/actions/app";
import { call, call_sso } from "@/actions/axios";

const { Option } = Select;
const { confirm } = Modal;

const Step = (props) => {
  const { history, match, cust_id } = props;
  const { groups, roles } = props;
  const [loading, setLoading] = React.useState(false);

  const isNew = match.params.id === "new";

  React.useEffect(() => {
    const onData = async () => {
      const { data: groups } = await dispatch(
        call({ url: "/api/customer/operators/groups/" + cust_id })
      );

      const temp = [];
      try {
        const array = groups.operator_group_id;
        Object.keys(array).forEach((key) =>
          temp.push({ ...array[key], id: key })
        );
      } catch (e) {}
      dispatch(
        SET_APPS([
          { path: ["groups"], value: temp },
          { path: ["groupsLoading"], value: false },
        ])
      );
    };
    onData();
    const { data, match, operator } = props;
    let item = _.find(data, { msisdn: match.params.id });
    if (!item) {
      if (operator) item = operator;
      else return;
    }
    setLoading(true);
    const getUser = async () => {
      if (item.represented_name) {
        var [last_name, first_name] = item.represented_name.split(" ");
      }
      const initValues = { ...item, last_name, first_name };
      if (!isNew) {
        try {
          const { data: user } = await dispatch(
            call_sso({ url: `api/user/${match.params.id}` })
          );
          if (user && user.email) initValues.email = user.email;
        } catch (e) {}
      }
      form.current.setFieldsValue(initValues);
      setLoading(false);
    };
    getUser(item);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  const getValues = async (type = "validate") => {
    let values;
    if (type === "validate") values = await form.current.validateFields();
    else values = await form.current.getFieldsValue();
    if (values.msisdn) values.msisdn = values.msisdn.replace(/[^\d]/g, "");
    values.represented_name = "";
    if (values.last_name) values.represented_name = values.last_name.trim();
    if (values.first_name)
      values.represented_name += ` ${values.first_name.trim()}`;

    return values;
  };

  const onSubmit = async () => {
    const { customer } = props;
    const values = await getValues();
    setLoading(true);
    if (isNew) {
      let isExists = false;
      try {
        await dispatch(
          call_sso({
            url: `/api/user/${values.msisdn}`,
          })
        );
        isExists = true;
      } catch (e) {}
      try {
        await dispatch(
          call_sso({
            url: `/api/user`,
            method: isExists ? "PUT" : "POST",
            data: {
              username: values.msisdn,
              firstName: values.first_name,
              lastName: values.last_name,
              email: values.email,
            },
          })
        );
        await dispatch(
          call_sso({
            url: `api/user/assign`,
            method: "POST",
            data: {
              bin: customer.idDocNum,
              username: values.msisdn,
            },
          })
        );
        await dispatch(
          call_sso({
            url: `api/user/service-profile`,
            method: "POST",
            data: {
              bin: customer.idDocNum,
              profile: values.role.toLocaleLowerCase(),
              serviceName: "autodialer",
              username: values.msisdn,
            },
          })
        );

        await dispatch(
          call({
            url: `/api/customer/operators/msisdns/assigned/${cust_id}/${values.msisdn}`,
            method: "POST",
            data: values,
          })
        );
      } catch (e) {
        setLoading(false);
        return notification.error({
          message: "Ошибка!",
          description: e.message,
        });
      }
    } else {
      try {
        await dispatch(
          call_sso({
            url: `/api/user`,
            method: "PUT",
            data: {
              username: values.msisdn,
              firstName: values.first_name,
              lastName: values.last_name,
              email: values.email,
            },
          })
        );
        await dispatch(
          call_sso({
            url: `api/user/service-profile`,
            method: "POST",
            data: {
              bin: customer.idDocNum,
              profile: values.role.toLocaleLowerCase(),
              serviceName: "autodialer",
              username: values.msisdn,
            },
          })
        );
        await dispatch(
          call({
            url: `/api/customer/operators/msisdns/assigned/${cust_id}/${values.msisdn}`,
            method: "PATCH",
            data: values,
          })
        );
      } catch (e) {
        setLoading(false);
        return notification.error({
          message: "Ошибка!",
          description: e.message,
        });
      }
    }
    dispatch(SET_APP(["operator"], null));
    setLoading(false);
    confirm({
      title: "Оператор успешно " + (isNew ? "добавлен" : "обновлен"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку операторов?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push("/operators");
      },
    });
  };

  const onGroup = async () => {
    const values = await getValues("get");
    dispatch(SET_APP(["operator"], values));
    let path = "/operators/";
    if (isNew) path += "new";
    else path += match.params.id;
    localStorage.setItem("backUrl", path);
    history.push("/groups");
  };

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Номер абонента (логин)"
              name="msisdn"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <MaskedInput
                mask="+7 111 111 11 11"
                disabled={loading || !isNew}
              />
            </Form.Item>
            <Form.Item
              label="Фамилия"
              name="last_name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Фамилия оператора" />
            </Form.Item>
            <Form.Item
              label="Имя"
              name="first_name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Имя оператора" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Почтовый адрес"
              name="email"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                disabled={loading || !isNew}
                placeholder="Почтовый адрес оператора"
              />
            </Form.Item>
            <Form.Item
              label="Роль"
              name="role"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Select disabled={loading} placeholder="Выберите роль">
                {roles.map((role) => (
                  <Option key={role.value} value={role.value}>
                    {role.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Группа"
              name="operator_group_id"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
              help={<Group onGroup={onGroup} />}
            >
              <Select disabled={loading} placeholder="Выберите группу">
                {groups.map((group) => (
                  <Option key={group.id} value={parseInt(group.id)}>
                    {group.operator_group_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button
          onClick={onSubmit}
          type="success"
          style={{ marginLeft: 14 }}
          loading={loading}
        >
          {isNew ? "Добавить" : "Обновить"}
        </Button>
        <Button
          onClick={() => loading || history.push("/operators")}
          type="light"
        >
          Назад
        </Button>
      </div>
    </>
  );
};

const Group = ({ onGroup }) => {
  return (
    <div>
      <Button className="info action" type="link" onClick={onGroup}>
        + добавить группу
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cust_id: state.auth.cust_id,
  customer: state.auth.customer,
  groups: state.app.groups,
  roles: state.app.roles,
  data: state.app.operators,
  operator: state.app.operator,
});

export default withRouter(connect(mapStateToProps)(Step));
