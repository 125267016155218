import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {PageHeader, Input, Button, Card, Radio} from "antd";
import {SET_APP, GET_CAMPAIGNS, GET_REPORT_OPERATORS, GET_REPORT_CAMPAIGNS} from "@/actions/app";
import TableOperators from "./Table/operators";
import TableCampaigns from "./Table/campaigns";
import "./index.less";
import {statuses} from "../Campaigns/Table/data";
import _ from "lodash";
import {columns as oColumns} from "./Table/operatorsData";
import {columns as cColumns} from "./Table/campaignsData";
import {exportToExcel} from "../../utils/excel";
import icon from "../../assets/images/excel.png";
import {call} from "../../actions/axios";
import {intToHHMMSS} from "../../utils";

let timer;

const ScreenReports = (props) => {
  const {data, cData, history, loading, campaigns} = props;
  const types = useSelector((state) => state.app.types);
  const [selected, setSelected] = useState("operators");
  // const [filter, setFilter] = useState([]);
  const dispatch = useDispatch();
  const activeCampaigns = campaigns.filter(c => {
    const st = c.campaign_status
    if (!(st === 'FINISHED' || st === 'ENDED' || st === 'DELETED')) {
      return true
    }
  })
  const filter = activeCampaigns.map(c => c.id);
  // console.log({filter})
  const getCampaignType = (id) => {
    const target = campaigns.find(c => c.id === id);
    const type = target.scenario_id;
    // console.log({id, target, type, types})
    return _.find(types, {value: parseInt(type)}).text;
  }

  const refresh = () => {
    // console.log({filter})
    dispatch(GET_CAMPAIGNS())
    switch (selected) {
      case "operators":
        dispatch(GET_REPORT_OPERATORS());
        break;
      case "campaigns":
        dispatch(GET_REPORT_CAMPAIGNS());
        break;
    }
    clearTimeout(timer);
    timer = setTimeout(() => refresh(), 60 * 1000);
  }
  const cItems = () => {
    let data = [];
    if (cData && cData.campaign_id) {
      for (let cId in cData.campaign_id) {
        data.push({...cData.campaign_id[cId], id: cId})
      }
    }
    data = data.sort((a, b) => {
      return new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
    })
    const rdata = [];
    for (let c of data) {
      const st = c.campaign_status;
      if (st === 'FINISHED' || st === 'ENDED' || st === 'DELETED') {
        continue;
      }
      const entry = {
        campaignIdAndName: `${c.id} - ${c.campaign_name}`,
        campaignFromDate: new Date(c.from_date).toLocaleString(),
        campaignEndDate: new Date(c.to_date).toLocaleString(),
        campaignType: getCampaignType(c.id),
        campaignState: statuses[c.campaign_status].text,
        activeOperatorsCount: c.operators_online,
        campaignContactsTotalCount: c.contacts_count,
        campaignContactsAnsweredCount: c.successfully_completed,
        campaignContactsAnsweredIncompleteCount: c.unsuccessfully_completed,
        campaignContactsDialedCount: c.dialed,
        campaignContactsNotDialedCount: c.unprocessed_contacts,
        answeredToTotalCount: (c.contacts_count > 0 ? c.answered / c.contacts_count * 100 : 0).toFixed(2) + "%",
        attempts: c.call_attempts,
        record: c.record_audio > 0 ? 'Включено' : "Выключено",
        campaignDescription: c.customer_comment
      }
      rdata.push(entry)
    }
    return rdata;
  }


  const items = () => {
    let t1 = [];
    if (data && data.msisdn) {
      for (let key in data.msisdn) {
        if (data.msisdn[key]) {
          for (let cId in data.msisdn[key].campaign_id) {
            t1.push({...data.msisdn[key].campaign_id[cId], id: cId});
          }
        }
      }
    }

    const rdata = [];
    t1 = t1.sort((a, b) => {
      return new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
    })
    for (let c of t1) {
      if (filter && filter.length > 0 && !filter.includes(c.id)) {
        continue;
      }
      const entry = {
        operatorNameAndGroup: `${c.operator_represented_name} - ${c.operator_group_name}`,
        campaignIdAndName: `${c.id} - ${c.campaign_name}`,
        campaignFromDate: new Date(c.from_date).toLocaleString(),
        campaignContactsTotalCount: c.contacts_count,
        campaignContactsAnsweredCount: c.successfully_completed_by_operator,
        answeredToTotalRate: c.successful_from_total_percent_by_operator,
        campaignContactsDialedCount: c.total_call_attempts_by_operator,
        answeredToDialedCount: c.successfully_completed_percent_by_operator,
        operatorPresence: c.presence_status,
        conversationTotalTime: intToHHMMSS(c.total_conversation_time_sec),
        conversationAverageTimePerCall: intToHHMMSS(c.medium_conversation_time_sec),
        dialingTotalTime: intToHHMMSS(c.total_call_waiting_time_sec),
        dialingAverageTimePerCall: intToHHMMSS(c.medium_call_waiting_time_sec)
      }
      rdata.push(entry);
    }
    return rdata;
  }

  useEffect(() => {
    dispatch(GET_CAMPAIGNS());
    dispatch(GET_REPORT_CAMPAIGNS());
    dispatch(GET_REPORT_OPERATORS());
    timer = setTimeout(() => refresh(), 60 * 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [])

  const onChange = (e) => {
    const {value} = e.target;
    if (value === "operators") {
      // console.log('GET REPS OPERS')
      // dispatch(GET_CAMPAIGNS());
    } else {
      // console.log('GET REPS CAMPS')
      // dispatch(GET_DELETED_CAMPAIGNS());
    }
    setSelected(value);
  };

  const exportData = () => {
    const columns = selected === 'operators' ? oColumns() : cColumns();
    const rows = selected === 'operators' ? items() : cItems();
    const title = selected === 'operators' ? 'Мониторинг - Операторы' : 'Мониторинг - Кампании'
    exportToExcel(rows, {
      columns,
      fileName: title,
      sheetName: new Date().toLocaleDateString("ru-RU"),
      title
    })
  }

  return (
    <>
      <PageHeader
        title="Журнал звонков"
        extra={[
          <Button key="export" onClick={exportData}
                  style={{
                    backgroundColor: '#18e345',
                    borderColor: '#18e345'
                  }}
                  icon={<img className="excel-icon" src={icon} alt=""/>}
          >
            Excel
          </Button>,
          <Button key="create" type="success" onClick={refresh}>
            Обновить
          </Button>,
        ]}
      />
      <Card
        bordered={false}
        title={
          <Radio.Group value={selected} onChange={onChange}>
            <Radio.Button value="operators">Операторы</Radio.Button>
            <Radio.Button value="campaigns">Кампании</Radio.Button>
          </Radio.Group>
        }
      >
        {
          selected === "operators" ?
            <TableOperators dataSource={items()} loading={loading} history={props.history}/> :
            <TableCampaigns dataSource={cItems()} loading={loading} history={props.history}/>
        }
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.reportOperators,
  cData: state.app.reportCampaigns,
  loading: state.app.reportLoading,
  campaigns: state.app.campaigns
});

export default connect(mapStateToProps)(ScreenReports);
